import i18next from 'i18next';
import UTIF from 'utif';

function Model3DViewer(container, width, height, inlab, _onLoadDone) {
	if(typeof inlab == 'undefined') inlab = false;

	this.container = container;
	this.inlab = inlab;

	if (!SupportDetector.webgl) {
		this.showWebGLWarning();
		return;
	}

	this.rendering_quality = 1;
	this.rendering_quality_setting = 'auto';
	this.rendering_quality_min = 0.5;
	this.rendering_quality_max = window.devicePixelRatio *2;
	this.texture_quality = 1;
	this.texture_quality_setting = 'auto';

	if(!Uint16Array.prototype.copyWithin) {
		this.fpses = [];
	} else {
		this.fpses = new Uint16Array(60);
	}
	this.fps_total = 0;
	this.fps_min = 30;
	this.fps_sacrifice = 50;
	this.quality_change_time = 256;
	this.quality_change_tick = 0;

	this.width = width;
	this.height = height;
	this.initalCameraPosition = {
		x: 0,
		y: 0,
		z: -8
	};

	this.isContained = false;
	this.containerIndex = -1;
	this.die = false;

	this.message = null;

	this.loaded = false;

	this.mouseX = 0;
	this.mouseY = 0;
	 
	this.windowHalfX = window.innerWidth / 2;
	this.windowHalfY = window.innerHeight / 2;

	THREE.ImageUtils.crossOrigin = 'anonymous';

	this.paused = false;

	this.loaderXHR = null;
	this.textureImgElem = null;

	this.onLoadDone = () => {
		_onLoadDone && _onLoadDone();
		this.onLoadDone = undefined;
	}
}

Model3DViewer._renderer = null;

Model3DViewer.prototype.init = function(product, model_name, variant, withOptions = true, recycleRenderer = true, renderer = null) {
	if(this.nosupport) return;

	var me = this;
	me.product = product;
	me.model_name = model_name;

	// Add the loader while we're initializing
	me.addLoader();

	//Init renderer
	if(renderer) {
		me.renderer = renderer;
	}
	else if(recycleRenderer && Model3DViewer._renderer) {
		me.renderer = Model3DViewer._renderer;
	} else {
		try {
			me.renderer = new THREE.WebGLRenderer({ 
				antialias: true,
				//preserveDrawingBuffer: true
				alpha: true
			});
		} catch(e) {
			me.showWebGLWarning();
			return;
		}

		if(recycleRenderer) {
			Model3DViewer._renderer = me.renderer;
		}
	}

	// You can adjust the cameras distance and set the FOV to something
	// different than 45°. The last two values set the clippling plane.
	me.camera = new THREE.PerspectiveCamera(30, this.width / this.height, 0.1, 10000 );
	me.camera_direction = new THREE.Vector3()
	me.camera.position.z = this.initalCameraPosition.z;

	me.initControls();
 
	// This is the scene we will add all objects to.
	me.scene = new THREE.Scene();

	//me.initDebug();

	//this.scene.background = new THREE.Color(0xffffff);
 	
 	if(product.static.use_glb === true || product.parent.static.use_glb === true){
 		me.ambient_light = new THREE.HemisphereLight(0xd8dfe5, 0xe5e5df, 0.5);
		me.scene.add(me.ambient_light);

		me.camera_light = new THREE.DirectionalLight(0xffffff, 0.5);
		me.camera_light.position.set(0,10,0);
		me.scene.add(me.camera_light);
 	} else {
		me.ambient_light = new THREE.AmbientLight( 0xdddddd );
		me.scene.add( me.ambient_light );

		me.camera_light = new THREE.DirectionalLight(0xffffff, 0.4);
		me.camera_light.position.set(0,0,0);
		me.scene.add(me.camera_light);
	}
 
	/*** Texture Loading ***/
	me.manager = new THREE.LoadingManager();
	me.manager.onProgress = function ( item, loaded, total ) {
		//console.log( item, loaded, total );
	};

	// Uh make sure variant isn't none
	if(variant == 'none') variant = null;

	if(typeof product.create3dModel === "function")
		product.create3dModel(me, variant);
	else 
		me.loadModel(product, variant);

	// We set the renderer to the size of the window and
	// append a canvas to our HTML page.
	me.renderer.setClearColor(0x4d4d4d, 0);
	me.renderer.setSize(me.width, me.height);
	
	if(!me.container.contains(me.renderer.domElement)) {
		me.container.appendChild( me.renderer.domElement );
	}

	// Add/reset options 
	//me.removeOptions();
	//if(withOptions) me.addOptions();

	// Set quality
	me.renderer.setPixelRatio(me.rendering_quality);

	//Enable this for SMAA, but it doesn't work with alpha
	//this.initComposer();
};

Model3DViewer.prototype.showWebGLWarning = function() {
	var warning = SupportDetector.getWebGLErrorMessage(this.inlab);
	this.container.appendChild(warning);
	this.nosupport = true;
	if(!this.inlab) window.aow.selectedThumb = 1;
};

Model3DViewer.prototype.addLoader = function(){
	var loaderHTML = 
	'<div class="image-gallery-3dmodel-loader valign-wrapper">'+
		'<div>Loading...</div>'+
		'<div class="progress">'+
			'<div class="determinate"></div>'+
		'</div>'+
	'</div>';
	$(this.container).append(loaderHTML);
};

Model3DViewer.prototype.removeLoader = function(){
	$(this.container).find('.image-gallery-3dmodel-loader').remove();
};

Model3DViewer.prototype.setContainer = function(container){
	if(!this.nosupport){
		this.container = container;
		this.container.appendChild(this.renderer.domElement);
	}
};

Model3DViewer.prototype.setRenderer = function(renderer){
	renderer.setClearColor(0xffffff, 1);
	renderer.setSize(this.width, this.height);
	this.container.removeChild( this.renderer.domElement );
	this.renderer = renderer;
	this.container.appendChild( this.renderer.domElement );

	this.initComposer();
};

Model3DViewer.prototype.initComposer = function() {
	this.composer = new THREE.EffectComposer(this.renderer);
	this.composer.addPass(new THREE.RenderPass(this.scene, this.camera));

	const pass = new THREE.SMAAPass(this.width, this.height);
	pass.renderToScreen = true;
	this.composer.addPass(pass);
}

Model3DViewer.prototype.setTextureCanvas = function(textureCanvas){
	if(!this.nosupport){
		this.texture = new THREE.Texture(textureCanvas);

		if(this.loaded) {
			this.product.set3dProperties(this);
		}
	}
};

Model3DViewer.prototype.setSize = function(width, height){
	if(this.renderer) {
		this.renderer.setSize(width, height);
	}

	if(this.composer) {
		this.composer.setSize(width, height);
	}
};
 
Model3DViewer.prototype.loadModel = function(product, variant, modelSuffix){
	if(!modelSuffix) modelSuffix = "";

	var me = this;

	// Abort if there's no scene
	if(!me.scene) return;

	// Just making sure we're ok
	// This test is probably wrong as it's supposed to be me.product.parent.static.has_subproducts. 
	me.product = product;
	if(!me.product.static.has_subproducts) {
		me.model_name = me.product.static.slug;
	}

	//Load the OBJ and MTL files,
	var model = "", material = "";

	/*var scene = product.getDefaultScene();

	if(product.static.preview_data.scenes[scene] && product.static.preview_data.scenes[scene].variants){
		// Fix: Sometimes the first time it runs variant is 0 even if there's some. Defaulting to [RANDOM KEY], sucks but hey.
		var variants = product.static.preview_data.scenes[scene].variants;
		if(variant === 0){
			for(var key in variants){
				if (variants.hasOwnProperty(key)) {
					variant = key;
					break;
				}
			}
		}
		if(product.static.preview_data.scenes[scene].variants[variant].obj)
			model = product.static.preview_data.scenes[scene].variants[variant].obj;
		else 
			model = me.model_name + '_' + variant;
	} else {
		model = me.model_name;
	}*/

	model = me.model_name;

	if(variant && variant !== "none") {
		model += '_' + variant;
	}

	model = me.product.getObj(model + modelSuffix);
	var loader, url;

	if(product.static.use_glb === true || product.parent.static.use_glb === true){
		model = THREE_GLB_URL + model + '.glb';
		loader = new THREE.GLTFLoader();
	} else {
		model = THREE_JSON_URL + model + ".3json";
		loader = new THREE.ObjectLoader();
	}

	loader.crossOrigin = 'anonymous';

	// load a resource
	this.loaderXHR = loader.load(
		// resource URL
		model,
		// Function when resource is loaded
		function ( object ) {
			try {
				me.object = object.scene || object; 

			if(variant !== 0 && variant !== 'none' && typeof variant !== 'undefined' && product.processObjectRotation){
				product.processObjectRotation(me.object, variant);
			}

				//Remove other objects 
				me.removeModels( me.scene );

				//Set 3D properties specific to Product
				me.product.set3dProperties(me);

				me.scene.add( me.object );

				//Call back function after object load
				if(typeof me.onObjectLoad !== 'undefined') {
					me.onObjectLoad();
				}

				me.loaded = true;

				me.onReady();
			} catch(e) {
				//Catch errors in case the callbacks are executed after the Model3DViewer was destroyed
				if(me.die) {
					//Modelviewer dead, graceful fail
				} else {
					//Actual error, throw it back
					throw e;
				}
			}

			// Remove the loader since we're done
			me.removeLoader();
			if(typeof me.onLoadDone === "function") me.onLoadDone();
		},
		function ( xhr ) {
			$(me.container).find('.image-gallery-3dmodel-loader').find('.determinate').css('width', (xhr.loaded / xhr.total * 100) + '%');
		},
		function ( xhr ) {
			if(typeof xhr.currentTarget !== 'undefined'){
				$(me.container).find('.image-gallery-3dmodel-loader').find('div').first().html('Error (Code: ' + xhr.currentTarget.status + ')');
			} else {
				$(me.container).find('.image-gallery-3dmodel-loader').find('div').first().html('An error occured');
			}
			console.log(xhr);
			$(me.container).find('.image-gallery-3dmodel-loader').find('div').first().css('color', 'red');
			$(me.container).find('.image-gallery-3dmodel-loader').find('.determinate').css('width', '100%');
			$(me.container).find('.image-gallery-3dmodel-loader').find('.determinate').css('background-color', 'red');
			$(me.container).find('.image-gallery-3dmodel-loader').css('background-color', 'palered');
			me.die = true;
		}
	);
};

Model3DViewer.prototype.removeModels = function(scene){
	if(scene){
		scene.children.forEach(function(object){
			if(object.type == 'Scene'){
				scene.remove(object);
			}
		});
	}
};

Model3DViewer.prototype.initControls = function() {
	if(this.nosupport) return;

	if(this.controls) this.controls.dispose();
	
	// These variables set the camera behaviour and sensitivity.
	this.controls = new THREE.OrbitControls(this.camera, this.container);
	this.controls.enablePan = false;
	this.controls.enableKeys = false;
	
	//Set product specific controls
	this.product.setThreeControls(this.controls);
	
	//Reset camera to minDistance
	this.initalCameraPosition.z = -this.controls.minDistance;
	this.camera.position.z = -this.controls.minDistance;

	//Reset camera to minDistance
	this.initalCameraPosition.z = -this.controls.minDistance;
	this.camera.position.z = -this.controls.minDistance;

	this.controls.rotateSpeed = this.product.rotationFromDistance(this.controls.minDistance);

	//Custom controls if we're not in the lab
	if(!this.inlab){
		this.controls.enableZoom = false;
	}
};

Model3DViewer.prototype.initDebug = function() {
	const geo = new THREE.SphereGeometry(0.05, 8, 6);
	const mat = new THREE.MeshPhongMaterial({ color: 0xff0000 });
	this.camera_light_debug = new THREE.Mesh(geo, mat)
	this.scene.add(this.camera_light_debug);

	const axes = new THREE.AxesHelper(10);
	this.scene.add(axes);
};

Model3DViewer.prototype.loadTexture = function(url) {
	var me = this;
	var loader = new THREE.ImageLoader(me.manager);
	
	loader.crossOrigin = 'anonymous';

	//Initialize textureCanvas
	var textureCanvas = document.createElement('canvas');
	
	// Make sure there's no random resize
	textureCanvas.width = 2; textureCanvas.height = 2;

	me.textureCanvas_ctx = textureCanvas.getContext('2d');

	//Set as texture for model viewer
	me.texture = new THREE.Texture(textureCanvas);

	if(me.product.imageDataTexture){
		const isTIF = url.substr(-3) == "tif";

		const handleLoad = function(e) {
			me.texture.anisotropy = me.renderer.capabilities.getMaxAnisotropy();
			
			if(isTIF){
				var ifds = UTIF.decode(e.target.response);
				window.tif = e.target.response;
				UTIF.decodeImage(e.target.response, ifds[0]);
			
				var colorRGBA = new Uint8ClampedArray(UTIF.toRGBA8(ifds[0]).buffer);

				textureCanvas.width = ifds[0].width;
				textureCanvas.height = ifds[0].height;

				var colorData = new ImageData(colorRGBA, ifds[0].width, ifds[0].height);

				me.textureCanvas_ctx.putImageData(colorData, 0, 0);
				me.textureImage = colorData;

				if(!!ifds[1]){
					UTIF.decodeImage(e.target.response, ifds[1]);
					var lightRGBA = new Uint8ClampedArray(UTIF.toRGBA8(ifds[1]).buffer);
					var lightData = new ImageData(lightRGBA, ifds[1].width, ifds[1].height);
					me.lightImage = lightData;
				}
			} else {
				textureCanvas.width = e.target.width;
				textureCanvas.height = e.target.height;

				me.textureCanvas_ctx.drawImage(e.target, 0, 0);
				me.textureImage = me.textureCanvas_ctx.getImageData(0, 0, e.target.width, e.target.height)
			}

			me.texture.needsUpdate = true;

			if(isset(me.object)) {
				me.product.updateThreeTexture(me);
			}

			if(typeof me.onTextureLoad !== 'undefined') {
				me.onTextureLoad();
			}
		}

		if(isTIF){
			var xhr = new XMLHttpRequest();
			xhr.open("GET", url);
			xhr.responseType = isTIF ? "arraybuffer" : "blob";
			xhr.onload = function(e) { handleLoad(e) }
			xhr.onerror = function(e) {
				if(me.die) {
					//Modelviewer dead, graceful fail
				} else {
					//Actual error, throw it back
					throw e;
				}
			};
			xhr.send();
		} else {
			const img = new Image();
			img.crossOrigin = "anonymous";
			img.onload = function(e) { handleLoad(e) }
			img.src = url;
		}
	} else {
		this.textureImgElem = loader.load(url, function ( image ) {
			try {
				me.texture.anisotropy = me.renderer.capabilities.getMaxAnisotropy(); //Set max anisotropy

				//Load image onto canvas
				textureCanvas.width = image.width;
				textureCanvas.height = image.height;
				me.textureCanvas_ctx.drawImage(image, 0, 0);

				me.textureImage = image;

				me.texture.needsUpdate = true;

				//Set new texture to object if it exists
				if(isset(me.object)) {
					me.product.updateThreeTexture(me);
				}

				//Call back function after textureLoad
				if(typeof me.onTextureLoad !== 'undefined') {
					me.onTextureLoad();
				}
			} catch(e) {
				//Catch errors in case the callbacks are executed after the Model3DViewer was destroyed
				if(me.die) {
					//Modelviewer dead, graceful fail
				} else {
					//Actual error, throw it back
					throw e;
				}
			}
		});
	}
};

Model3DViewer.prototype.onReady = function() {
	if(!this.inlab){
		this.animate();
	}
};

/*** The Loop ***/
Model3DViewer.prototype.animate = function(loop) {
	if(this.nosupport) return;
	
	var me = this; 

	//Loop defaults to true
	if(typeof loop === 'undefined')
		loop = true;

	if(!me.paused && me.loaded){
		var now = Date.now();
		Model3DViewer.frameTime = now - me.lastFrame;
		me.lastFrame = now;

		if(!me.quality_change_tick && (me.rendering_quality_setting == 'auto' || me.texture_quality_setting == 'auto')){
			me.quality_change_tick = me.quality_change_time;

			if(!isNaN(Model3DViewer.frameTime)){ 
				me.fpses.copyWithin(0, 1);
				me.fpses[me.fpses.length - 1] = Model3DViewer.frameTime;
				
				me.fps_total = 0;
				for (var v = 0; v < me.fpses.length; v++){
					me.fps_total += me.fpses[v];
				}

				var average = 1000 / (me.fps_total / me.fpses.length);

				if(me.rendering_quality_setting == 'auto'){
					var render_quality_changed = false;
					if(average < me.fps_min && me.rendering_quality > me.rendering_quality_min){
						me.rendering_quality -= 0.1;
						render_quality_changed = true;
					} else if(average > me.fps_sacrifice && me.rendering_quality < me.rendering_quality_max - 0.1){
						me.rendering_quality += 0.1;
						render_quality_changed = true;
					}

					me.rendering_quality = Math.max(me.rendering_quality, me.rendering_quality_min);

					if(render_quality_changed){
						//AOW.UI.toast(__('create_ns_toast.rendering_quality_changed') + me.rendering_quality.toFixed(2));
						me.renderer.setPixelRatio(me.rendering_quality);
					}
				}

				if(this.inlab) {
					if(me.texture_quality_setting == 'auto') {
						var texture_quality_changed = false;
						if(average < 30){
							if(me.texture_quality == 1) {
								me.texture_quality = 0.5;
								texture_quality_changed = true;
							} else if (me.texture_quality == 0.5) {
								me.texture_quality = 0.125;
								texture_quality_changed = true;
							}
						} else if(average > 30) {
							if(me.texture_quality == 0.5) {
								me.texture_quality = 1;
								texture_quality_changed = true;
							} else if (me.texture_quality == 0.125) {
								me.texture_quality = 0.5;
								texture_quality_changed = true;
							}
						}

						if(texture_quality_changed){
							//AOW.UI.toast(__('create_ns_toast.texture_quality_changed') + me.texture_quality.toFixed(2));
						}
					}
				}
			}
		} else {
			me.quality_change_tick--;
		}

		me.update();
	}

	// This function calls itself on every frame. You can for example change
	// the objects rotation on every call to create a turntable animation.
	if(loop && !this.die) {
		requestAnimationFrame(function() {
			me.animate();
		});
	}
};

Model3DViewer.prototype.update = function(){
	if(!this.controls) return;

	this.controls.update();

	if(typeof this.product.update3dModel === "function")
		this.product.update3dModel(this);

	this.camera.getWorldDirection(this.camera_direction)

	var light_distance = 0
	var light_position = new THREE.Vector3(
		this.camera.position.x + this.camera_direction.x * light_distance,
		this.camera.position.y + this.camera_direction.y * light_distance,
		this.camera.position.z + this.camera_direction.z * light_distance,
	)
	this.camera_light.position.set(light_position.x, light_position.y, light_position.z)
	if(this.camera_light_debug) {
		this.camera_light_debug.position.set(light_position.x, light_position.y, light_position.z)
	}

	//this.camera_light.target = new THREE.Vector3()

	if(typeof this.lut !== 'undefined' && this.lut.ready){
		this.lut.texture.needsUpdate = true;
		this.lut.composer.render();
		this.texture.image = this.lut.canvas;
		this.texture.needsUpdate = true;
	}

	try {
		if(this.composer) {
			this.composer.render();
		} else {
			this.renderer.render(this.scene, this.camera);
		}
	} catch(e) {
		console.log(e);
		this.showWebGLWarning();
	}
};

Model3DViewer.prototype.resetCamera = function(x, y, z){
	//Reset camera
	this.camera.position.x = x || this.initalCameraPosition.x;
	this.camera.position.y = y || this.initalCameraPosition.y;
	this.camera.position.z = z || this.initalCameraPosition.z;
};

Model3DViewer.prototype.setFixedView = function(viewName) {
	if(this.nosupport || !this.controls) return;

	this.resetCamera();

	this.controls.reset();

	//Set product's view
	this.product.setThreeView(this.camera, viewName, this.initalCameraPosition.z);

	if(this.object){
		this.camera.lookAt(this.object.position);
	}

	this.camera_light.position.x = this.camera.position.x;
	this.camera_light.position.y = this.camera.position.y;
	this.camera_light.position.z = this.camera.position.z;

	//Set autoRotate if it's one
	if(this.autoRotate) {
		this.controls.autoRotate = true;
	} else {
		this.controls.autoRotate = false;
	}
};

/**
 * Checks if the mesh is empty and removes it if it is
 * @param  {Object} mesh        Mesh object
 * @param  {number} child_index Index of the mesh in the object's children
 * @return {boolean}             Returns TRUE if the mesh is fine, FALSE otherwise
 */
Model3DViewer.checkMesh = function(mesh, child_index) {
	if(mesh.geometry.faces.length > 0 && mesh.geometry.vertices.length > 0) {
		// do stuff here with the good mesh
		
		for (var i = 0; i < mesh.children.length; i++)
			if (!checkMesh(mesh.children[i], true, i))
				i--; // child was removed, so step back

		return true;
	} 
	// empty mesh! this causes WebGL errors
	else {
		if (mesh.parent !== null)
			mesh.parent.children.splice(child_index, 1);

		//Mesh has zero faces/vertices so we remove it
		mesh = null;

		return false;
	}
};

/**
 * Sets persistent auto rotate on 3D viewer, will persist even after control reset, setFixedView, etc.
 * @param  {Number} speed Rotation speed
 * @return {undefined}
 */
Model3DViewer.prototype.enableAutoRotate = function(speed) {
	this.setFixedView('front');
	this.controls.autoRotate = true;
	this.controls.autoRotateSpeed = speed;
};

/**
 * Disables persisten auto rotate
 * @return {undefined}
 */
Model3DViewer.prototype.disableAutoRotate = function() {
	this.controls.autoRotate = false;
	this.controls.autoRotateSpeed = 0;
};

Model3DViewer.prototype.enableLut = function(src) {
	this.disableLut(false);
	this.lut = new LUThree(this.texture.image, src);
};

Model3DViewer.prototype.disableLut = function(update) {
	if(typeof update == 'undefined') update = true;
	if(this.lut && this.lut.ready){
		this.lut.canvas = null;
		var quad1 = this.lut.lutPass.quad;
		this.lut.lutPass.scene.remove(quad1);
		quad1.geometry.dispose();

		var quad2 = this.lut.texturePass.quad;
		this.lut.texturePass.scene.remove(quad2);
		quad2.geometry.dispose();

		this.lut.lutPass.material.dispose();
		this.lut.texturePass.material.dispose();
		this.lut.texture.dispose();
		this.lut.lut.dispose();

		var quad3 = this.lut.composer.copyPass.quad;
		this.lut.composer.copyPass.scene.remove(quad3);
		quad3.geometry.dispose();
		this.lut.composer.copyPass.material.dispose();

		this.lut.composer.renderTarget1.texture.dispose();
		this.lut.composer.renderTarget2.texture.dispose();
		this.lut.composer.renderTarget1.dispose();
		this.lut.composer.renderTarget2.dispose();

		this.lut.composer.renderTarget1 = null;
		this.lut.composer.renderTarget2 = null;

		this.lut.composer.writeBuffer = null;
		this.lut.composer.readBuffer = null;
		this.lut.composer = null;

		/*this.lut.renderer.forceContextLoss();
		this.lut.renderer.context = null;
		this.lut.renderer.domElement = null;
		this.lut.renderer = null;
		this.lut.composer = null;*/
		this.texture.image = this.lut.sourceCanvas;
	}
	this.lut = undefined;
	if(update) this.texture.needsUpdate = true;
};

Model3DViewer.prototype.destroy = function() {
	Model3DViewer.destroy(this);
};

Model3DViewer.destroy = function(viewer){
	if(window.aow.modelViewer == viewer){
		window.aow.modelViewer = null;
	}

	//abort model load if there is one
	if(viewer.loaderXHR && viewer.loaderXHR.abort) {
		//Disabled this due to conflict with how THREE.js already prevents duplicate requests
		//viewer.loaderXHR.abort();
	}

	//abort texture load if there is one (only if Cache is disabled or it breaks it)
	if(!THREE.Cache.enabled && typeof viewer.textureImgElem !== 'undefined' && viewer.textureImgElem !== null) {
		viewer.textureImgElem.onload = null;
		viewer.textureImgElem.onerror = null;
		viewer.textureImgElem.src = '';
	}

	if(viewer.nosupport) return;

	var context = viewer.renderer.domElement.getContext('webgl');

	if(context) {
		context.clear(context.COLOR_BUFFER_BIT);
	}
	viewer.scene.remove(viewer.object);
	if(typeof viewer.object !== 'undefined') Model3DViewer.destroyObject(viewer.object);
	//viewer.container.removeChild(viewer.renderer.domElement);
	viewer.disableLut(false);

	viewer.controls = null;
	viewer.die = true;

	return;
	/*if(typeof viewer.object !== 'undefined') Model3DViewer.destroyObject(viewer.object);
	viewer.disableLut(false);
	viewer.removeModels(this.scene);
	viewer.scene = null;
	viewer.camera = null;
	viewer.renderer.forceContextLoss();
	viewer.renderer.context = null;
	viewer.renderer.domElement = null;
	viewer.renderer = null;
	viewer.controls = null;
	viewer.die = true;
	if(window.aow.modelViewer == viewer){
		window.aow.modelViewer = null;
	}
	viewer = null;*/
};

Model3DViewer.destroyObject = function(object){
	if (object !== null) {
		for (var i = 0; i < object.children.length; i++){
			Model3DViewer.destroyObject(object.children[i]);
		}
		if (object.geometry){
			object.geometry.dispose();
			object.geometry = undefined;
		}
		if (object.material){
			if (object.material.map && object.material.dispose){
				object.material.map.dispose();
				object.material.map = undefined;
			}
			if(object.material.dispose) {
				object.material.dispose();
				object.material = undefined;
			}
		}
	}
	object = undefined;
};

Model3DViewer.prototype.addOptions = function(){
	var me = this;

	if(!i18next.isInitialized) {
		setTimeout(function(){
			me.addOptions();
		}, 100);
		return;
	}

	// Don't add it if there's already one (Subproduct stuff)
	if($('#modelViewer_options').length) return;
	if($('#modelViewer_options-btn').length) return;

	var modal = $('<div id="modelViewer_options" class="modal medium dark-mode">'+
		'<div class="modal-content" >' +
			'<h3 class="modal-title">' + __("create_ns_model3dviewer.options.title") + '</h3>' +
			'<div class="row">' +

			'</div>' +
		'</div>' + 
		'<div class="modal-footer">' +
			'<button type="button" class="btn-flat waves-effect modal-close">' + __('create_ns_model3dviewer.options.close') + '</button>' +
		'</div>' +
	'</div>');

	modal.find('.modal-content .row').append(
		'<div class="col s4">' +
			'<h4>' + __('create_ns_model3dviewer.options.rendering') + '</h4>' +
			'<p><input id="3d-quality-low" value="low" type="radio" name="3d-quality"><label for="3d-quality-low">' + __('create_ns_model3dviewer.options.setting_low') + '</label></p>' +
			'<p><input id="3d-quality-medium" value="medium" type="radio" name="3d-quality"><label for="3d-quality-medium">' + __('create_ns_model3dviewer.options.setting_medium') + '</label></p>' +
			'<p><input id="3d-quality-high" value="high" type="radio" name="3d-quality"><label for="3d-quality-high">' + __('create_ns_model3dviewer.options.setting_high') + '</label></p>' +
			'<p><input checked id="3d-quality-auto" value="auto" type="radio" name="3d-quality"><label for="3d-quality-auto">' + __('create_ns_model3dviewer.options.setting_auto') + '</label></p>' +
		'</div>'
	);

	$(modal).find('input[name=3d-quality]').on('change', function(e) {
		var value = $('input[name=3d-quality]:checked').val();

		switch(value){
			case 'low':
				me.rendering_quality = me.rendering_quality_min;
				break;
			case 'medium':
				me.rendering_quality = me.rendering_quality_min + (me.rendering_quality_max - me.rendering_quality_min / 2);
				break;
			case 'high':
				me.rendering_quality = me.rendering_quality_max;
				break;
		}

		me.rendering_quality_setting = value;

		//AOW.UI.toast(__('create_ns_toast.rendering_quality_changed') + me.rendering_quality.toFixed(2));

		me.renderer.setPixelRatio(me.rendering_quality);
	});

	if(this.inlab) {
		modal.find('.modal-content .row').append(
			'<div class="col s4">' +
				'<h4>' + __('create_ns_model3dviewer.options.texture') + '</h4>' +
				'<p><input id="texture-quality-low" value="low" type="radio" name="texture-quality"><label for="texture-quality-low">' + __('create_ns_model3dviewer.options.setting_low') + '</label></p>' +
				'<p><input id="texture-quality-medium" value="medium" type="radio" name="texture-quality"><label for="texture-quality-medium">' + __('create_ns_model3dviewer.options.setting_medium') + '</label></p>' +
				'<p><input id="texture-quality-high" value="high" type="radio" name="texture-quality"><label for="texture-quality-high">' + __('create_ns_model3dviewer.options.setting_high') + '</label></p>' +
				'<p><input checked id="texture-quality-auto" value="auto" type="radio" name="texture-quality"><label for="texture-quality-auto">' + __('create_ns_model3dviewer.options.setting_auto') + '</label></p>' +
			'</div>'
		);

		$(modal).find('input[name=texture-quality]').on('change', function(e) {
			var value = $('input[name=texture-quality]:checked').val();

			switch(value){
				case 'low':
					me.texture_quality = 0.125;
					break;
				case 'medium':
					me.texture_quality = 0.5;
					break;
				case 'high':
					me.texture_quality = 1;
					break;
			}

			me.texture_quality_setting = value;

			//AOW.UI.toast(__('create_ns_toast.texture_quality_changed') + me.texture_quality.toFixed(2));
		});
	}

	modal.find('.modal-content .row').append(
		'<div class="col s4">' +
			'<h4>' + __('create_ns_model3dviewer.options.download_title') + '</h4>' +
			'<a href="#" class="btn-flat btn-flat-bg main-grey waves-effect waves-light btn-3d-download" target="_blank"><i class="material-icons left">file_download</i>' + __('create_ns_model3dviewer.options.download_button') + '</a>' +
		'</div>'
	);

	$(modal).find('.btn-3d-download').on('click', function(e) {
		var viewer = me;
		
		if(me.isContained && window.aow.modelViewerContainer){
			viewer = window.aow.modelViewerContainer.currentViewer;
		}

		viewer.renderer.setPixelRatio(1);

		viewer.renderer.render(viewer.scene, viewer.camera);

		if (window.navigator.msSaveBlob) {
			window.navigator.msSaveBlob(viewer.renderer.domElement.msToBlob(), __('create_ns_model3dviewer.options.filename'));
			e.preventDefault();
		} else {
			this.setAttribute('download', __('create_ns_model3dviewer.options.filename'));
			this.setAttribute('href', viewer.renderer.domElement.toDataURL());
		}

		viewer.renderer.setPixelRatio(viewer.rendering_quality);
	});

	modal.modal();

	$(document.body).append(modal);

	var button = $('<button id="modelViewer_options-btn" type="button" class="btn-flat btn-flat-bg main-grey waves-effect waves-light" target="_blank"><i class="material-icons left">settings</i></button>');
	$(me.container).append(button);

	button.on('click', function(){
		modal.modal('open');
	});
};

Model3DViewer.prototype.removeOptions = function(){
	$('#modelViewer_options').remove();
	$('#modelViewer_options-btn').remove();
};

Model3DViewer.prototype.clone = function() {
	var i, newModelViewer =  $.extend(true,{},this);

	newModelViewer.scene = this.scene.clone();

	//Empty the scene
	for(i = newModelViewer.scene.children.length - 1; i >= 0; i--) { 
		newModelViewer.scene.remove(newModelViewer.scene.children[i]);
	}

	//Deep clone the object
	newModelViewer.object = this.object.clone();
	newModelViewer.camera_light = this.camera_light.clone();
	newModelViewer.ambient_light = this.ambient_light.clone();
	newModelViewer.scene.add(newModelViewer.ambient_light);
	newModelViewer.scene.add(newModelViewer.camera_light);
	newModelViewer.scene.add(newModelViewer.object);
	
	for (i = 0; i < this.object.children.length; i++) {
		if(!newModelViewer.object.children[i].material) continue;
		newModelViewer.object.children[i].material = this.object.children[i].material.clone();
	}

	return newModelViewer;
};

export default Model3DViewer;