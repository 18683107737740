// Allows handling of SVG files and on-the-fly modification
AOW.SVGHandler = function(params){
	if(typeof params.src == 'undefined') return;

	this.svg = '';

	this.src = params.src;
	this.callback = params.callback || null;
	this.outputs = params.outputs || [function(data){
		return data;
	}];
};

// Gets the SVG, modifies the data multiple times differently
// according to the functions in this.outputs then calls 
// the callback method with all the different datas in an
// array. Always returns an array even if there's only one data.
AOW.SVGHandler.prototype.getSources = function(){
	var me = this;
	$.get(me.src, function(data) {
		me.svg = $(data).find('svg');
		var out = [];
		for(var i = 0; i < me.outputs.length; i++){
			var output_data = me.outputs[i](me.svg.clone());
			var serialized = new XMLSerializer().serializeToString(output_data.get(0));
			var output_src = 'data:image/svg+xml,' + encodeURIComponent(serialized);
			out.push(output_src);
		}
		if(typeof me.callback == 'function')
			me.callback(out);
	});
};

// Used to chain filters
AOW.SVGHandler.chain = function(filters){
	return function(data){
		for(var i = 0; i < filters.length; i++){
			data = filters[i](data);
		}
		return data;
	};
};

// All possible filters for the svg outputs
AOW.SVGHandler.translate = function(data){
	data.find('text').each(function(){
		$(this).text(__('master_ns_svg.' + $(this).text()));
	});
	return data;
};

// Gets the sheet from a style tag making sure safari isn't stupid
AOW.SVGHandler.getSheet = function(_style){
	// Gets the sheet directly if it's available
	var sheet = _style.sheet;
	if(sheet) return sheet;

	// Babysitting safari
	var fake = document.createElement('style');
	fake.innerHTML = _style.innerHTML;
	document.body.appendChild(fake);
	sheet = fake.sheet;
	document.body.removeChild(fake);

	return sheet;
};