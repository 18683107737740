AOW.AccountSettings = function() {};
AOW.AccountSettings.init = function() {
	var me = $('body');

	//Init events
	me.find('.account-btn').on('click', AOW.AccountSettings.accountBtnClick);
};

AOW.AccountSettings.accountBtnClick =  function(){
	$('#account-settings-view').css('right','-100%');

	if($('#account-settings').hasClass('active')) {
		$('#account-settings').removeClass('active');
		$('#account-settings').data('hideTimeout', setTimeout(function() {
			$('#account-settings').hide();
		}, 250));
	} else {
		clearTimeout($('#account-settings').data('hideTimeout'));
		$('#account-settings').show();
		setTimeout(function() {
			$('#account-settings').addClass('active');
		}, 0);
	}

	//Click anywhere to dismiss
	$(document).on('click.account-btn', function(e) {
		if(!$('#account-settings').hasClass('active')) return true;


		if($(e.target).closest('#account-settings').length) {
			return true;	
		}

		e.preventDefault();

		AOW.AccountSettings.accountBtnClick();

		return false;
	});
};