require('../Dashboard');

AOW.Dashboard.SyncProduct = function($el, slug, htmlContent, toggleSyncStatus, onCloseSyncProductClick, page) {
	
	this.slug = slug;
	this.$el = $el; // #product-sync-view
	this.page = page;
	
	this.$el.css('width', ($('.main-page').width()));
	this.$el.html('');
	this.$el.html(htmlContent);
	this.$el.removeClass('hide');
	this.$el.css('right', '0px');
	
	this.storeProductId = this.$el.find('ul.collection').data('store-product-id');
	
	this.toggleSyncStatus = toggleSyncStatus;
	this.onCloseSyncProductClick = onCloseSyncProductClick;
	this.choosePreviousVariant = null;
	
	this.init();
};

AOW.Dashboard.SyncProduct.prototype = Object.assign({}, AOW.Dashboard.SyncProduct.prototype, {
	
	init: function() {
		// window.addEventListener('popstate', function(e) {
		// 	
		// });
		
		/* collectionItemAvatars is an array containing relevant data for every variant rows in the DOM:
		[{
			$row,
			variantId,
			variantTitle,
			ignored
		}] */
		var me = this;
		me.collectionItemAvatars = [];
		
		me.$el.find('li.colletion-item.avatar > .row').each(function() {
			
			$row = $(this);
			
			var newItemAvatar = {
				$row: $row,
				variantId: $row.data('variant-id'),
				variantTitle: $row.find('span.title').text(),
				ignored: $row.data('ignored')
			};
			
			me.initItemAvatarEvents(newItemAvatar);
			
			me.collectionItemAvatars.push(newItemAvatar);
		});
		
		this.$el.find('#unsync-all').on('click', this.confirmUnsyncAll.bind(this));

		this.$el.find('#ignore-all').on('click', this.confirmIgnoreAll.bind(this));

		this.$el.find('#unignore-all').on('click', this.confirmUnignoreAll.bind(this));
		
		this.$el.find('.close-product-sync').on('click', this.onCloseSyncProductClick);

		//Click anywhere to dismiss
		$('#main-dashboard').find('.loader-overlay').on('click.sync-product', function(e) {
			if($(e.target).closest('#product-sync-view').length) {
				return true;	
			}

			e.preventDefault();

			me.onCloseSyncProductClick(e);

			return false;
		});
			
		AOW.Dashboard.startLoader(true);
	},
	
	getItemAvatar: function(variantId) {
		var itemAvatar;
		this.collectionItemAvatars.every(function(elem) {
			if( elem.variantId == variantId ) {
				itemAvatar = elem;
				return false;
			}
			return true;
		});
		
		return itemAvatar;
	},
	
	initItemAvatarEvents: function(itemAvatar) {
		var $row = itemAvatar.$row;
		
		$changeBtn = $row.find('.change-btn');
		$unsyncBtn = $row.find('.unsync-btn');
		$ignoreBtn = $row.find('.ignore-btn');
		$chooseBtn = $row.find('.choose-btn');
		$unignoreBtn = $row.find('.unignore-btn');
		
		if( $changeBtn.length > 0 )
			$changeBtn.on('click', itemAvatar, this.chooseProductModal.bind(this));
		
		if( $unsyncBtn.length > 0 )
			$unsyncBtn.on('click', itemAvatar, this.confirmUnsyncVariant.bind(this));
		
		if( $chooseBtn.length > 0 )
			$chooseBtn.on('click', itemAvatar, this.chooseProductModal.bind(this));
		
		if( $ignoreBtn.length > 0 )
			$ignoreBtn.on('click', Object.assign({}, itemAvatar, {ignore: 1}), this.ignoreVariant.bind(this));
		
		if( $unignoreBtn.length > 0 )
			$unignoreBtn.on('click', Object.assign({}, itemAvatar, {ignore: 0}), this.ignoreVariant.bind(this));
		
	},
	
	confirmUnsyncVariant: function(e) {
		
		var me = this,
			data = e.data;
		
		AOW.UI.dialog({
			url: 'd/'+me.slug+'/confirm-unsync-variant',
			success: function() {
				var $dialog = $(this);
				$dialog.on('submit', Object.assign({}, data, {$dialog: $dialog}), me.unsyncVariant.bind(me));
			}
		});
		
	},
	
	unsyncVariant: function(e) {
		e.preventDefault();
		var me = this,
			data = e.data,
			$dialog = e.data.$dialog;
		
		AOW.request({
			url: 'd/'+me.slug+'/unsync-variant',
			method: 'POST',
			data: {
				id_store_product: me.storeProductId,
				id_variant: data.variantId,
				variant_title: data.variantTitle
			},
			success: function(response) {
				me.renderNewVariantRow(data.variantId, response.data.html);
				// me.toggleSyncStatus(response.data.productIsSynced);
				$dialog.closeDialog();
				AOW.UI.toast( response.data.name+" variant successfully unsynchronized." );
			}
		});
	},
	
	ignoreVariant: function(e) {
		
		e.preventDefault();
		var me = this,
			variantTitle = me.getItemAvatar(e.data.variantId).variantTitle,
			ignored = e.data.ignore;
			
		AOW.request({
			url: 'd/'+me.slug+'/ignore-variant',
			method: 'POST',
			data: {
				id_store_product: me.storeProductId,
				id_variant: e.data.variantId,
				ignored: ignored,
				variant_title: variantTitle
			},
			success: function(response) {
				me.renderNewVariantRow(e.data.variantId, response.data.html);
				// me.toggleSyncStatus(response.data.productIsSynced);
				if( ignored ) AOW.UI.toast("Your variant has been succesfully ignored.");
				else AOW.UI.toast("Your variant has been succesfully unignored.");
			}
		});
	},
	
	chooseProductModal: function(e) {
		e.preventDefault();
		var me = this,
			data = e.data;
	
		AOW.UI.dialog({
			url: 'd/'+me.slug+'/choose-product/',
			data: {
				id_store_product: me.storeProductId,
				id_variant: data.variantId
			},
			success: function(response) {
				var dialog = this;
				me.searchProductMenu(dialog, response);
			}
		});
	},
	
	searchProductMenu: function(dialog, response) {
		var me = this;
		var $productSearch = $(dialog).find('.product-search');
		
		me.productSearchSelect2($productSearch);
		// If a variant in DB was found, make a call to append the options menu with its options as default selected
		if(response.data.storeVariant) {
			me.appendOptionsMenu({
				productDesign_id: response.data.storeVariant.productDesign_id,
				product_id: response.data.storeVariant.product_id,
				variant_id: response.data.storeVariant.id_variant,
				storeVariant: response.data.storeVariant
			}, dialog);
		}
		//Show the previous variant (temporarily saved). Useful when syncing all variants of the same product
		else if(this.choosePreviousVariant !== null) {
			me.appendOptionsMenu({
				productDesign_id: this.choosePreviousVariant.productDesign_id,
				product_id: this.choosePreviousVariant.product_id,
				variant_id: response.data.id_variant,
				storeVariant: this.choosePreviousVariant
			}, dialog);
		}
		else {
			//Open the results 500ms right after it's initialized (so the modal has time to appear)
			setTimeout(function() {
				$productSearch.select2('open');
			}, 500);
		}
		
		$productSearch.on('select2:selecting', function(e) {
			//If it's a product design, call to order
			if(e.params.args.data.type == 'productDesign') {
				e.preventDefault();
				$(dialog).find('.product-search').select2('close');
		
				$('.syncProduct-btn[data-id="'+me.storeProductId+'"]').addClass('loading');
		
				var data = {
					variant_id: response.data.id_variant,
					product_id: e.params.args.data.id_product,
					productDesign_id: e.params.args.data.id,
					product_slug: e.params.args.data.product_slug
				};

				me.choosePreviousVariant = {
					productDesign_id: data.productDesign_id,
					product_id: data.product_id,
					storeVariant: response.data
				};
		
				me.appendOptionsMenu(data, dialog);
				return false;
			}
		});
		
		$productSearch.on('select2:select', function(e) {
			//If we selected a category or product, reopen the results box right after so we don't have to click on the search again
			if(e.params.data.type != 'productDesign')
				$productSearch.select2('open');
		});
	},
	
	productSearchSelect2: function($productSearch) {
		var me = this;
	
		var searchUrl = 'd/'+me.slug+'/search-products';
	
		//Add lang prefix if there's one
		if(typeof LANG_PREFIX === 'string' && LANG_PREFIX !== "") {
			searchUrl = LANG_PREFIX+'/'+searchUrl;
		}
	
		searchUrl = 'ajax/'+searchUrl;
	
		$productSearch.select2({
			ajax: {
				url: searchUrl,
				dataType: 'json',
				delay: 100,
				data: function (params) {
	
					var return_data = {
						q: params.term, //search term
						page: params.page //page
					};
	
					//Check for selected category
					var i, selected_data = $productSearch.select2('data');
					for (i = 0; i < selected_data.length; i++) {
						if(selected_data[i].type == 'category') {
							return_data.category = selected_data[i].id;
							break;
						}
					}
	
					//Check for selected product
					for (i = 0; i < selected_data.length; i++) {
						if(selected_data[i].type == 'product') {
							return_data.product = selected_data[i].id;
							break;
						}
					}
	
					return return_data;
				},
				
				processResults: function (response, params) {
					// parse the results into the format expected by Select2
					// since we are using custom formatting functions we do not need to
					// alter the remote JSON data, except to indicate that infinite
					// scrolling can be used
					params.page = params.page || 1;
	
					var items = response.data.items;
	
					return {
						results: items,
						pagination: {
							more: (params.page * 25) < parseInt(response.data.count_items,10)
						}
					};
				},
				cache: false
			},
			
			placeholder: __('account_ns_messages.enterProductName'),
			
			escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
			
			minimumInputLength: 0,
			
			templateResult: function(item) {
				if (item.loading) return item.text;
	
				var markup = '';
	
				if(item.type == 'productDesign') {
					markup += '<div class="result-item result-productDesign">'+
								'<img src="'+item.preview+'" height="48"/>'+
								'<div class="result-text"><strong>' +item.name+ '</strong><br/>'+item.product_category+' | '+item.product_name+'</div>'+
							'</div>';
				} else {
					markup += '<div class="result-item">'+item.data.name+'</div>';
				}
	
				markup += '';
	
				return markup;
			},
			
			templateSelection: function(item) {
				return item.text;
			}
		});
	},
	
	appendOptionsMenu: function(data, dialog) {
		/* data {
			productDesign_id
			product_id
			variant_id
		} */
		var me = this;
		AOW.langRequest({
			url: 'd/'+me.slug+'/product-options',
			method: 'POST',
			data: data,
			success: function(response) {
				$.when( $(dialog).find('#options-menu').html(response.data.html) )
				.then(function() {
					window.aow.initUI('#options-menu');

					//Focus on submit btn
					$(dialog).find('form button[type="submit"]').focus();
					$(dialog).find('form select').off('change.option-form').on('change.option-form', function() {
						$(dialog).find('form button[type="submit"]').focus();
					});

					$(dialog).find('form').off('submit.option-form').on('submit.option-form', function(e) {
						me.syncVariant(e, dialog, data);

						return false;
					});
				});
			}
		});
	},
	
	syncVariant: function(e, dialog, data) {
		me = this;
		e.preventDefault();
		
		data.id_integration_product = me.storeProductId;
		data.options = {};
		
		$(dialog).find('.product-option').each(function() {
			if($(this).is('select')) {
				data.options[$(this).attr('name')] = $(this).val();
			}
		});
		
		data.variant_title = me.getItemAvatar(data.variant_id).variantTitle;
		
		AOW.request({
			url: 'd/'+me.slug+'/sync-variant',
			method: 'POST',
			data: data,
			success: function(response) {
				me.renderNewVariantRow(data.variant_id, response.data.html);
				AOW.UI.toast(response.data.name+' variant successfully synchronized.');
				// me.toggleSyncStatus(response.data.productIsSynced);
				$(dialog).closeDialog();
			}
		});
	},
	
	renderNewVariantRow: function(variantId, dataHtml) {
		var me = this;
		
		var itemAvatar = me.getItemAvatar(variantId);
		
		itemAvatar.$row.html(dataHtml);
		
		me.initItemAvatarEvents(itemAvatar);
	},
	
	confirmUnsyncAll: function(e) {
		var me = this,
			data = e.data;
		
		AOW.UI.dialog({
			url: 'd/'+me.slug+'/confirm-unsync-all',
			success: function() {
				var $dialog = $(this);
				$dialog.on('submit', {$dialog: $dialog}, me.unsyncAll.bind(me));
			}
		});
	},
	
	unsyncAll: function(e) {
		e.preventDefault();
		
		var me = this,
			$dialog = e.data.$dialog,
			$submitBtn = $dialog.find('button[type="submit"]'),
			$deleteInStore = $dialog.find('#delete-in-store');
		
		AOW.UI.addLoader( $submitBtn.attr('disabled', true) );
		
		var postData = {
			id_integration_product: me.storeProductId,
			deleteInStore: ($deleteInStore.length > 0 && $deleteInStore.prop('checked')) ? 1 : 0,
			variantsInfo: []
		};
		this.collectionItemAvatars.forEach(function(itemAvatar) {
			postData.variantsInfo.push({
				id_integration_variant: itemAvatar.variantId,
				variant_title: itemAvatar.variantTitle,
				ignored: itemAvatar.ignored
			});
		});
		
		AOW.request({
			url: 'd/'+me.slug+'/unsync-all',
			method: 'POST',
			data: postData,
			success: function(response) {
				if( response.data.deletedInStore ) {
					$dialog.closeDialog();
					AOW.UI.toast("Product was succesfully deleted from store.");
					me.onCloseSyncProductClick();
				} else {
					// me.toggleSyncStatus(response.data.productIsSynced);
					$dialog.closeDialog();
					AOW.UI.toast("All variants succesfully unsynced.");
					response.data.renderedVariants.forEach(function(renderedVariant) {
						me.renderNewVariantRow( renderedVariant.id, renderedVariant.html);
					});
				}
			}
		});
	},

	confirmIgnoreAll:function(e) {
		var me = this,
			data = e.data;
		
		AOW.UI.dialog({
			url: 'd/'+me.slug+'/confirm-ignore-all',
			success: function() {
				var $dialog = $(this);
				$dialog.on('submit', {$dialog: $dialog}, me.ignoreAll.bind(me));
			}
		});
	},

	ignoreAll: function(e) {
		e.preventDefault();
		
		var me = this,
			$dialog = e.data.$dialog,
			$submitBtn = $dialog.find('button[type="submit"]');
		
		AOW.UI.addLoader( $submitBtn.attr('disabled', true) );
		
		var postData = {
			id_integration_product: me.storeProductId,
			variantsInfo: []
		};
		this.collectionItemAvatars.forEach(function(itemAvatar) {
			postData.variantsInfo.push({
				id_integration_variant: itemAvatar.variantId,
				variant_title: itemAvatar.variantTitle,
				ignored: itemAvatar.ignored
			});
		});
		
		AOW.request({
			url: 'd/'+me.slug+'/ignore-all-variants',
			method: 'POST',
			data: postData,
			success: function(response) {
				$dialog.closeDialog();

				AOW.UI.toast(__('account_ns_toast.ignoredAll'));
				me.page.pushStateToHistory(me.page.state);
			}
		});
	},

	confirmUnignoreAll:function(e) {
		var me = this,
			data = e.data;
		
		AOW.UI.dialog({
			url: 'd/'+me.slug+'/confirm-unignore-all',
			success: function() {
				var $dialog = $(this);
				$dialog.on('submit', {$dialog: $dialog}, me.unignoreAll.bind(me));
			}
		});
	},

	unignoreAll: function(e) {
		e.preventDefault();
		
		var me = this,
			$dialog = e.data.$dialog,
			$submitBtn = $dialog.find('button[type="submit"]');
		
		AOW.UI.addLoader( $submitBtn.attr('disabled', true) );
		
		var postData = {
			id_integration_product: me.storeProductId,
			variantsInfo: []
		};
		this.collectionItemAvatars.forEach(function(itemAvatar) {
			postData.variantsInfo.push({
				id_integration_variant: itemAvatar.variantId,
				variant_title: itemAvatar.variantTitle,
				ignored: itemAvatar.ignored
			});
		});
		
		AOW.request({
			url: 'd/'+me.slug+'/unignore-all-variants',
			method: 'POST',
			data: postData,
			success: function(response) {
				$dialog.closeDialog();

				AOW.UI.toast(__('account_ns_toast.unignoredAll'));
				me.page.pushStateToHistory(me.page.state);
			}
		});
	},
	
	close: function() {
		var me = this;

		this.$el.css('right', '-100%');
		$('body').css('cssText','overflow: auto !important;');

		$('#main-dashboard').find('.loader-overlay').off('click.sync-product');

		setTimeout(function() {
			me.$el.html('');
		},500);
	}
});
