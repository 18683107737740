//require('bower_components/weakmap-polyfill/weakmap-polyfill.js');
require('core-js');
import SimpleBar from 'simplebar';
window.SimpleBar = SimpleBar;

require('script-loader!jquery');
require('script-loader!select2');

require('script-loader!../../node_modules/materialize-css/dist/js/materialize.js');
require('script-loader!../../vendor/artofwhere/aow-lib/resources/frontend-lib/jquery.ui.widget.js');
require('script-loader!../../vendor/artofwhere/aow-lib/resources/frontend-lib/jquery.menu-aim.js');
require('script-loader!../../vendor/artofwhere/aow-lib/resources/frontend-lib/jquery.iframe-transport.js');
require('script-loader!../../vendor/artofwhere/aow-lib/resources/frontend-lib/jquery.html5uploader.min.js');
require('script-loader!../../vendor/artofwhere/aow-lib/resources/frontend-lib/parallax.js');
require('script-loader!../../vendor/artofwhere/aow-lib/resources/frontend-lib/trip.min.js');

require('js-cookie');
require('script-loader!knockout/build/output/knockout-latest.js');
require('script-loader!knockout-daterangepicker/dist/daterangepicker.js');

require('script-loader!../../vendor/artofwhere/aow-lib/resources/js/AOW.js');

require('./ProductDesignView.js');
require('script-loader!./routes.js');
require('script-loader!./account/SocialApp.js');


require('script-loader!./create/Product.js');

var req = require.context("script-loader!./create/ProductCategories", true, /^(.*\.(js$))[^.]*$/im);
req.keys().forEach(function(key){
    req(key);
});

//Parent products first, prefixed by _
var req = require.context("script-loader!./create/Products", true, /^(.*\/_[a-zA-Z0-9]+\.(js$))[^.]*$/im);
req.keys().forEach(function(key){
    req(key);
});

var req = require.context("script-loader!./create/Products", true, /^(.*\/[a-zA-Z0-9]+\.(js$))[^.]*$/im);
req.keys().forEach(function(key){
    req(key);
});

require('./info/Dropship.js');
require('./info/Help.js');
require('./info/ProductionAndShipping.js');
require('./info/Fabrics.js');
require('./SVGHandler.js');
require('./ShippingTables.js');
require('./dashboard/AccountSettings.js');