require('../Dashboard');

AOW.Dashboard.Connect = function(slug) {
	
	this.slug = slug;
	this.$el					= $('#connect');
	
	AOW.Dashboard.call(this);
};

AOW.Dashboard.Connect.prototype = Object.assign({}, AOW.Dashboard.Connect.prototype, {});