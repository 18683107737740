require('../../Dashboard');

AOW.Dashboard.SyncAOWItem = function() {
	
	this.$itemSearch = this.$el.find('#item-search');
	this.$selectedItem = this.$el.find('#selected-item');
	this.$chooseOption = this.$el.find('#choose-option');
	this.$storePage = this.$el.find('#store-page');
	this.$form = this.$el.find('form');
	this.$submitBtn = this.$form.find('button[type="submit"]');
	
	this.allowedItemTypes = [
		'productDesign',
		'collection'
	];
	
	this.selectedItem = null;
};

AOW.Dashboard.SyncAOWItem.prototype = Object.assign({}, AOW.Dashboard.SyncAOWItem.prototype, {
	
	init: function() {
		var me = this;
		
		me.initSelect2();
		
		setTimeout(function() {
			me.$itemSearch.select2('open');
		}, 500);
		
		me.$itemSearch.on('select2:selecting', me.onSelect2Selecting.bind(me));

		me.$itemSearch.on('select2:select', function(e) {
			//If we selected a category or product, reopen the results box right after so we don't have to click on the search again
			if(e.params.data.type != 'productDesign')
				me.$itemSearch.select2('open');
		});
		
		me.$form.on('submit', me.onSubmit.bind(me));
	},
	
	initSelect2: function() {
		var me = this;
		
		var searchUrl = 'd/'+me.slug+'/search-'+me.itemType+'s';
		//Add lang prefix if there's one
		if(typeof LANG_PREFIX === 'string' && LANG_PREFIX !== "") {
			searchUrl = LANG_PREFIX+'/'+searchUrl;
		}
	
		searchUrl = 'ajax/'+searchUrl;
	
		me.$itemSearch.select2({
			ajax: {
				url: searchUrl,
				dataType: 'json',
				delay: 100,
				
				data: me.select2Data.bind(me),
				
				processResults: me.select2ProcessResult.bind(me),
				
				cache: false
			},
			placeholder: __('account_ns_messages.enterProductName'),
			
			escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
			
			minimumInputLength: 0,
			
			templateResult: me.templateResult.bind(me),
			
			templateSelection: me.templateSelection.bind(me)
		});
	},
	
	onSelect2Selecting: function(e) {
		var me = this;
		
		//If it's an allowed item type, call to order
		if( me.allowedItemTypes.indexOf(e.params.args.data.type) > -1 ) {
			e.preventDefault();
			me.selectedItem = e.params.args.data;
		
			me.appendItemPreview(
				me.selectedItem.name,
				me.selectedItem.preview,
				me.selectedItem.etsy_listing_options || null
			);
		}
	},
	
	appendItemPreview: function(name, preview, etsy_listing_options) {
		var me = this;
		
		me.$selectedItem.html(
			'<h3>'+name+'</h3>' +
			'<img src="'+preview+'" alt="" />'
		);
		
		if( etsy_listing_options ) {
			me.selectedItem.chosenOption = etsy_listing_options[0].id;
			
			me.$chooseOption.html(
				'<h3>Choose an option</h3>' +
				'<select name="chooseOption" id="select-choose-option">' +
				etsy_listing_options.reduce(function(html, option) {
					return html + '<option value="'+option.id+'">'+option.name+'</option>';
				}, '') +
				'</select>'
			);
			me.$selectChooseOption = me.$el.find('#select-choose-option');
			me.$selectChooseOption.material_select();
			
			me.$selectChooseOption.on('change', function(e) {
				$option = $(e.target).find('option:selected');
				
				me.selectedItem.chosenOption = $option[0].value;
			});
		}
		
		me.$itemSearch.select2('close');
		me.$submitBtn.attr('disabled', false);
	},
	
	onSubmit: function(e) {
		e.preventDefault();
		var me = this;
		
		AOW.UI.addLoader(me.$submitBtn.attr('disabled', true));
		
		//Get store page if there is one
		var store_page = null;

		if(me.$storePage.length)  {
			store_page = me.$storePage.val();
		}

		//Remove data we don't need for the request
		var data = {
			type: me.selectedItem.type,
			id: me.selectedItem.id,
			id_product: me.selectedItem.id_product,
			chosenOption: me.selectedItem.chosenOption,
			store_page: store_page,
		};

		AOW.request({
			method: 'POST',
			data: data,
			url: 'd/'+me.slug+'/add-'+me.itemType,
			success: function(response) {
				AOW.UI.toast("Your "+me.itemType+" has been succesfully added.");
				$(e.currentTarget).closest('.modal').closeDialog();
			},
			error: function(response) {
				var parsedResponseText = JSON.parse(response.responseText);

				message = typeof parsedResponseText.data.error != 'undefined' ? parsedResponseText.data.error : "Something went wrong. Try again later.";

				if (parsedResponseText.data.error == 'product-sku-exists')
					toastKey = 'productSkuExists';
				else if (parsedResponseText.data.error == 'product-name-exists')
					toastKey = 'productNameExists';
				else if (parsedResponseText.data.error == 'server-under-maintenance')
					if (me.slug == 'etsy')
						toastKey = 'etsyUnderMaintenance';
					else
						toastKey = '';
				else
					toastKey = '';

				AOW.UI.toast(__('account_ns_toast.' + (toastKey !== '' ? toastKey : 'productAddToStoreError')), 'error');
				
				AOW.UI.removeLoader( me.$submitBtn.attr('disabled', false) );
			}
		});
	}
	
});