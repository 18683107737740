require('../../Dashboard');

AOW.Dashboard.SyncAOWProduct = function($el, slug) {
	
	this.$el = $el;
	this.slug = slug;
	this.itemType = "product";
	
	AOW.Dashboard.SyncAOWItem.call(this);
	
	this.init();
};

// SyncAOWProduct extends SyncAOWItem
AOW.Dashboard.SyncAOWProduct.prototype = AOW.Dashboard.SyncAOWItem.prototype;
AOW.Dashboard.SyncAOWProduct.prototype.constructor = AOW.Dashboard.SyncAOWProduct;

AOW.Dashboard.SyncAOWProduct.prototype = Object.assign({}, AOW.Dashboard.SyncAOWProduct.prototype, {
	
	select2Data: function(params) {
		var me = this;
		var return_data = {
			q: params.term, //search term
			page: params.page //page
		};

		//Check for selected category
		var i, selected_data = me.$itemSearch.select2('data');
		for (i = 0; i < selected_data.length; i++) {
			if(selected_data[i].type == 'category') {
				return_data.category = selected_data[i].id;
				break;
			}
		}

		//Check for selected product
		for (i = 0; i < selected_data.length; i++) {
			if(selected_data[i].type == 'product') {
				return_data.product = selected_data[i].id;
				break;
			}
		}

		return return_data;
	},
	
	select2ProcessResult: function(response, params) {
		// parse the results into the format expected by Select2
		// since we are using custom formatting functions we do not need to
		// alter the remote JSON data, except to indicate that infinite
		// scrolling can be used
		params.page = params.page || 1;

		var items = response.data.items;

		return {
			results: items,
			pagination: {
				more: (params.page * 25) < parseInt(response.data.count_items,10)
			}
		};
	},
	
	templateResult: function(item) {
		if (item.loading) return item.text;

		var markup = '';

		if(item.type == 'productDesign') {
			markup += '<div class="result-item result-productDesign">'+
						'<img src="'+item.preview+'" height="48"/>'+
						'<div class="result-text"><strong>' +item.name+ '</strong><br/>'+item.product_category+' | '+item.product_name+'</div>'+
					'</div>';
		} else {
			markup += '<div class="result-item">'+item.data.name+'</div>';
		}

		markup += '';

		return markup;
	},
	
	templateSelection: function(item) {
		return item.text;
	},
});