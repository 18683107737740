AOW.Fabrics = function() {};
AOW.Fabrics.init = function() {
	//Resize fabric cards to equal height
	var cardResize = function() {
		$('.fabrics-section .card').css('height', 'auto');

		var maxHeight = 0;
		$('.fabrics-section .card').each(function() {
			if($(this).height() > maxHeight) {
				maxHeight = $(this).height();
			}
		});

		$('.fabrics-section .card').css('height', maxHeight+'px');	
	}

	$(window).resize(cardResize);
	cardResize();

	//Resize idea cards to equal height
	var ideaCardResize = function() {
		$('.ideas-section .card-2020').css('height', 'auto');

		var maxHeight = 0;
		$('.ideas-section .card-2020').each(function() {
			if($(this).height() > maxHeight) {
				maxHeight = $(this).height();
			}
		});

		$('.ideas-section .card-2020').css('height', maxHeight+'px');	
	}

	$(window).resize(ideaCardResize);
	ideaCardResize();


	//Copy button
	$('.text-to-copy').each(function() {
		var $parent = $(this);

		$parent.find('button').on('click', function(e) {
			e.preventDefault();

			var elem = $parent.find('p')[0];
			var range = document.createRange();
            range.selectNode(elem);
			window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);

			document.execCommand('copy');
		});
	});


	//Fabric page carousel
	$('.fabrics-page').find('.header .carousel').each(function() {
		let $carousel = $(this);
		let count = $carousel.find('.carousel-item').length;

		$carousel.find('.carousel-item').each(function(index) {
			let offset = -100 + index*100;
			$(this).css('left', String(offset)+'%');
			$(this).data('index', index);
		});

		let timeout = $carousel.data('timeout') || 5000;
		let sliderFunc = function() {

			$carousel.find('.carousel-item').each(function() {
				let prevIndex = $(this).data('index'),
					newIndex = -1;

				if(prevIndex == 0) {
					newIndex = count - 1;
				} else {
					newIndex = prevIndex - 1;
				}

				let offset = -100 + newIndex*100;
				$(this).css('left', String(offset)+'%');

				if(newIndex == 1) {
					$(this).css('opacity', 1);
				} else {
					$(this).css('opacity', 0);
				}

				$(this).data('index', newIndex);
			});

			setTimeout(sliderFunc, timeout);
		};

		sliderFunc();
	});
}