AOW.ProductionAndShipping = function() {};
AOW.ProductionAndShipping.init = function() {
	if($('#production-and-shipping-page').length > 0){

		$('.timeline-step.received').addClass('active');
		$('.arrows .arrow.prev').css({'background':'#848484','cursor': 'initial'});

		var timelineImg = $('#timeline-img img');

		$('.arrow').on('click', function(){
			var arrow = $(this);
			var activeStep = $('.timeline-step.active');
			var step = activeStep.attr('data-step');

			if(arrow.hasClass('prev')) {
				if(step > 1){
					step--;
					activeStep.removeClass('active');
					$('.timeline-step[data-step="'+step+'"]').addClass('active');
				}
			} else {
				if(step <= 7){
					step++;
					activeStep.removeClass('active');
					$('.timeline-step[data-step="'+step+'"]').addClass('active');
				}
			}

			switch(step) {
				case 1:
					timelineImg.css({'margin-left':'-25px'});
					break;
				case 2:
					timelineImg.css({'margin-left':'-340px'});
					break;
				case 3:
					timelineImg.css({'margin-left':'-506px'});
					break;
				case 4:
					timelineImg.css({'margin-left':'-598px'});
					break;
				case 5:
					timelineImg.css({'margin-left':'-839px'});
					break;
				case 6:
					timelineImg.css({'margin-left':'-1179px'});
					break;
				case 7:
					timelineImg.css({'margin-left':'-1269px'});
					break;
				case 8:
					timelineImg.css({'margin-left':'-1434px'});
					break;
			}

			if(step == 1) {
				$('.arrow.prev').css({'background':'#848484','cursor': 'initial'});
			} else {
				$('.arrow.prev').css({'background':'#fff','cursor': 'pointer'});
			}

			if(step == 8) {
				$('.arrow.next').css({'background':'#848484','cursor': 'initial'});
			} else {
				$('.arrow.next').css({'background':'#fff','cursor': 'pointer'});
			}
		});
	}
};