AOW.Help = function() {};
AOW.Help.init = function(){
	if($('#help-search-form').length > 0) {
		AOW.request({
			method: 'GET',
			url: $('#help-search-form').data('suggest-url'),
			data: {},
			success: function(response) {
				var listPages = response.data.listPages;
				var pages = {};
				var pagesData = [];

				$.each(listPages, function(index, value){
					pages[value.title] = null;
					pagesData[value.title] = value;
				});

				$('#help-search').autocomplete({
				    data: pages,
				    limit: 10,
				    minLength: 3,
				    onAutocomplete: function(val) {
						window.location.href = pagesData[val].url;
				    }
				});

				//Clear btn
				$('#help-search-form').find('.btn-clear').on('click', function(e) {
					e.preventDefault();

					$('#help-search').val('').trigger('keyup');

					return false;
				});

				$('#help-search').on('keyup', function() {
					//Hide/show clear if there's something in the input
					if($(this).val() === "") {
						$('#help-search-form').find('.btn-clear').hide();
					} else{
						$('#help-search-form').find('.btn-clear').show();
					}
				});
			}
		});
	}
};