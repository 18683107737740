require('../Dashboard');

AOW.Dashboard.Products = function(slug) {
	this.slug = slug;
	this.selectedAllPages = false;
	this.productQueueInterval = undefined;
	this.productsInQueue = undefined;
	
	AOW.Dashboard.call(this);
	this.init();
};

//Dasnboard.Products extends Dashboard
AOW.Dashboard.Products.prototype = AOW.Dashboard.prototype;
AOW.Dashboard.Products.prototype.constructor = AOW.Dashboard;

AOW.Dashboard.Products.prototype = Object.assign({}, AOW.Dashboard.Products.prototype, {
	
	init: function() {
		this.$lastProductChecked = null;
		
		this.syncAOWItemDialog = null;
		// instanciation of AOW.Dashboard.SyncProduct()
		this.syncProduct = null;
		// build params object by parsing query portion of url
		this.state = {
			id_integration_product: this.extractIntegrationProductIdFromPath(),
			queryParams: AOW.parseUrlQuery(window.location.search) || {}
		};
		
		this.cacheDom();
		
		this.bindEvents();
		
		this.initWindowHistory();
		
		this.state.queryParams.count = this.$selectProductCount.data("page-limit");
		
		this.renderSelectCount(this.state.queryParams.count);
		this.renderSelectSort(this.$selectSort.data("sort") || null);
		
		this.syncProduct = null;
		if (this.state.id_integration_product) {
			
			this.syncProduct = new AOW.Dashboard.SyncProduct(
				this.$productSyncView,
				this.slug,
				this.$productSyncView.html(),
				this.toggleSyncStatus,
				this.onCloseSyncProductClick.bind(this),
				this
			);
		}
		
		// A way to check to see if user has requested to sync products
		if (this.$syncProductsAlert.length)
			this.refreshProductsAfterSynced();

		if (this.$addingProductsAlert.length)
			this.refreshProductQueue();
		
		window.history.replaceState(this.state, '');
	},
	
	cacheDom: function() {
		this.$refreshButton			= this.$el.find('a.refresh-button');
		this.$formProductsFilter	= this.$el.find('#form-products-filter');
		
		this.$inputProductSearch	= this.$formProductsFilter.find('input#product-search');
		this.$productTypeItems		= this.$formProductsFilter.find('#product-type li');
		this.$productTypeTags		= this.$formProductsFilter.find('#sorted-btns a.product-type-tag');
		this.$stateItems			= this.$formProductsFilter.find('#sync-state li');
		this.$stateTag				= this.$formProductsFilter.find('#sorted-btns a.state-tag');
		this.$sortLayoutBtn			= this.$formProductsFilter.find('a.sort-layout');
		this.$selectProductCount	= this.$formProductsFilter.find('select#product-count');
		this.$selectSort			= this.$formProductsFilter.find('select#sortings');
		
		this.$syncProductBtn		= this.$el.find('.sync-product-btn');
		this.$productSyncView		= this.$el.find('#product-sync-view');
		this.$overlayProductSync	= this.$el.find('#modal-overlay-product-sync');
		this.$syncAOWItemBtn		= this.$el.find('#sync-aow-item').find('.sync-aow-item-btn');
		this.$productsFiltersNav	= this.$el.find('#products-filters-nav');
		this.$productsSortingNav	= this.$el.find('#products-sorting-nav');
		this.$dropdownButton		= this.$el.find('a.dropdown-button');
		
		this.$infoBadgeContainer	= this.$el.find('#info-badge-container');
		
		this.$productsContainer		= this.$el.find('#products-container');
		
		this.$paginationContainer	= this.$el.find('#pagination-container');

		this.$syncProductsAlert		= this.$el.find('#syncing-products-info-container');
		this.$addingProductsAlert	= this.$el.find('#adding-products-info-container');
		this.$errorAlert			= this.$el.find('#error-alert-container');
		this.$errorMessage			= this.$el.find('#error-alert-message');
	},
	
	bindEvents: function() {
		this.$dropdownButton.on('click', this.preventDropdownRedirect.bind(this));
		
		this.$refreshButton.on('click', this.disableRefreshButton.bind(this));
		
		this.$syncAOWItemBtn.on('click', this.onSyncAOWItemClicked.bind(this));
			
		//Filters
		this.$productTypeItems.on('click', this.onProductTypeSelected.bind(this));
		this.$productTypeTags.on('click', this.onProductTypeTagClicked.bind(this));
		this.$stateTag.on('click', this.onStateTagClick.bind(this));
		this.$stateItems.on('click', this.onSyncStateSelected.bind(this));
		this.$formProductsFilter.on('submit', this.onProductsFilterSubmit.bind(this));

		//Layout / Sorting / Paging
		this.$selectSort.change('click', this.onSortingSelect.bind(this));
		this.$sortLayoutBtn.on('click', this.onLayoutClick.bind(this));
		this.$selectProductCount.on('change', this.productCountSelected.bind(this));
		this.$paginationContainer.on('click', 'ul.pagination > li > a', this.onPaginationClick.bind(this));
		
		this.$productsContainer.on('click', '.sync-product-btn', this.onSyncProductClick.bind(this));
		
		this.$productsContainer.on('click', '.card.product', this.onClickProduct.bind(this));
		this.$productsContainer.on('click', 'input.filled-in', this.onSelectProduct.bind(this));

		this.$productsContainer.on('click', 'span.unsync-all', this.confirmUnsyncAll.bind(this));
		this.$productsContainer.on('click', 'span.ignore-btn', this.confirmIgnoreAll.bind(this));
		this.$productsContainer.on('click', 'span.unignore-btn', this.confirmUnignoreAll.bind(this));
		this.$productsContainer.on('click', 'span.delete-btn', this.confirmDeleteProduct.bind(this));

		this.$el.on('click', 'span.batch-unsync', this.confirmBatchUnsync.bind(this));
		this.$el.on('click', 'span.batch-ignore', this.confirmBatchIgnore.bind(this));
		this.$el.on('click', 'span.batch-unignore', this.confirmBatchUnignore.bind(this));
		this.$el.on('click', 'span.batch-delete', this.confirmBatchDelete.bind(this));

		this.$el.on('click', 'ul#select-all li#all', this.clickSelectAll.bind(this));
		this.$el.on('click', 'ul#select-all li#none', this.clickUnSelectAll.bind(this));

		this.$el.on('click', '.select-all-pages-btn', this.onClickSelectAllPages.bind(this));
	},
	
	getCurrentUrl: function(state) {
		var url = PAGE_URL;
		var urlArray = url.split('/');
		var nbParts = 3;

		if(LANG_PREFIX !== "")
			nbParts++;

		urlArray = urlArray.slice(0, nbParts);
		url = urlArray.join('/');

		// if a fake id is present in the state, tail it to the url
		if (state.id_integration_product) url += ('/'+state.id_integration_product);
        // tail the search params to the
		url += AOW.buildUrlQuery(state.queryParams);
		
		return url;
	},
	
	applyState: function(state) {
		if (state.id_integration_product) {
			this.executeSyncProductView(state);
		} else {
			if (this.syncProduct) this.syncProduct.close();
			
			this.requestProductsAjax(state);
		}
		
		this.state = state;
	},
	
	extractIntegrationProductIdFromPath: function() {
		var path = PAGE_ROUTE.split("/");
		if (path.length >= 4) return path[3];
		
		return null;
	},
	
	preventDropdownRedirect: function(e) {
		e.preventDefault();
	},
	
	executeSyncProductView: function(state) {
		
		var id = state.id_integration_product;
		var $statusSpan = this.$el.find('article#store-product-'+id).find('span.sync-status');
		var url = this.getCurrentUrl(state);
		
		var me = this;
		AOW.Dashboard.page({
			method: 'GET',
			url: url,
			success: function(response) {
				
				me.syncProduct = new AOW.Dashboard.SyncProduct(
					me.$productSyncView,
					me.slug,
					response.data.html,
					me.toggleSyncStatus,
					me.onCloseSyncProductClick.bind(me),
					me
				);
			},
			error: function() {
				AOW.UI.toast("It seems like your requested product couldn't be found.");
			}
		});
	},
	
	toggleSyncStatus: function(storeProductId, status) {
		$storeProduct = this.$el.find('#store-product-'+storeProductId);
		
		
		// if( isSynced === true )
		// 	this.$statusSpan.addClass('synced').removeClass('unsynced').html('<strong>Synced</strong>');
		// else
		// 	this.$statusSpan.addClass('unsynced').removeClass('synced').html('<strong>Not synced</strong>');
	},
	
	onProductsFilterKeypress: function(e) {
		/* CURRENTLY NOT USED ANYWHERE */
		var me = this;
		var keyCode = e.which;
		
		if( keyCode === 13 ) { // Enter
			me.$formProductsFilter.submit();
		}
	},
	
	onProductsFilterSubmit: function(e) {
		e.preventDefault();
		
		var search = this.$inputProductSearch.val().trim();

		this.state.queryParams.search = search;
		this.state.queryParams.p = 1;
		
		this.unSelectAll();
		this.pushStateToHistory(this.state);
	},
	
	onSyncProductClick: function(e) {
		e.preventDefault();
		id = $(e.currentTarget).attr('data-id').toString();
		
		this.state.id_integration_product = id;
		this.unSelectAll();
		this.pushStateToHistory(this.state);
	},
	
	onCloseSyncProductClick: function(e) {
		this.state.id_integration_product = null;
		this.pushStateToHistory(this.state);
	},
	
	onSyncAOWItemClicked: function(e) {
		var me = this,
			itemType = $(e.currentTarget).data('itemtype'),
			capItemType = itemType.charAt(0).toUpperCase() + itemType.slice(1); // uppercase first letter
		
		AOW.UI.dialog({
			url: 'd/'+me.slug+'/sync-aow-item',
			data: {
				itemType: itemType
			},
			success: function() {
				var $dialog = $(this);
				
				me.syncAOWItemDialog = new AOW.Dashboard["SyncAOW"+capItemType]($dialog, me.slug);
				
				$dialog.on('dialog.close', function() {
					me.syncAOWItemDialog = null;
					me.onCloseSyncProductClick(e);
				});
			}
		});
	},
	
	showFilerTypeBtn: function(type) {
		this.$formProductsFilter.find('#sorted-btns #btn-type').html(type+'<i class="material-icons">clear</i>');
		this.$formProductsFilter.find('#sorted-btns #btn-type').removeClass('hide');
	},
	
	onProductTypeSelected: function(e) {
		e.preventDefault();
		
		var $li = $(e.currentTarget);
		var id_product_type = $li.data('product-type-id').toString();
        
		this.state.queryParams.productTypes = this.state.queryParams.productTypes || [];
		this.state.queryParams.productTypes.push(id_product_type);
		this.state.queryParams.p = 1;
		
		this.unSelectAll();
		this.pushStateToHistory(this.state);
	},

	onSyncStateSelected: function(e) {
		e.preventDefault();
		
		var $li = $(e.currentTarget);
		var state = $li.data('state').toString();
        
		this.state.queryParams.state = state;
		this.state.queryParams.p = 1;
        
        this.unSelectAll();
		this.pushStateToHistory(this.state);
	},
	
	onProductTypeTagClicked: function(e) {
		e.preventDefault();
		
		var productTypes = this.state.queryParams.productTypes || [],
			toRemove = e.currentTarget.getAttribute('data-product-type-id').toString(),
			i = productTypes.indexOf(toRemove);

		if (i > -1) productTypes.splice(i, 1);
		
		this.state.id_integration_product = null;
		this.state.queryParams.productTypes = productTypes;
		this.state.queryParams.p = 1;
		
		this.unSelectAll();
		this.pushStateToHistory(this.state);
	},
	
	onStateTagClick: function(e) {
		var $tag = $(e.curentTarget);
		
		delete this.state.queryParams.state;
		
		this.unSelectAll();
		this.pushStateToHistory(this.state);
	},
	
	onSortingSelect: function(e) {
		e.preventDefault();
        
		this.state.queryParams.sort = $(e.currentTarget).val();
		this.state.queryParams.p = 1;

		this.unSelectAll();
		this.pushStateToHistory(this.state);
	},
	
	onLayoutClick: function(e) {
		e.preventDefault();
		
		var layout = $(e.currentTarget).data('layout');
		
		this.state.queryParams.layout = layout;
		this.applyState(this.state);
	},
	
	productCountSelected: function(e) {
		e.preventDefault();
		
		var count = $(e.currentTarget).val();
		
		this.state.queryParams.count = count;
		this.state.queryParams.p = 1;

		this.unSelectAll();
		this.pushStateToHistory(this.state);
	},
	
	onPaginationClick: function(e) {
		e.preventDefault();
		
		this.state.queryParams.p = $(e.currentTarget).data('p');

		this.unSelectAll();
		this.pushStateToHistory(this.state);
	},
	
	disableRefreshButton: function(e) {
		AOW.Dashboard.startLoader();
		$(e.target).attr('disabled', true);
	},
	
	requestProductsAjax: function(state) {
		var me = this,
			url = this.getCurrentUrl(state);
		
		AOW.Dashboard.startLoader();
		AOW.request({
			method: "get",
			url: url,
			success: function(response) {
				me.render(response.data);
			},
			error: function(response) {
				AOW.UI.toast(__('account_ns_toast.errorOccured'));
				AOW.Dashboard.stopLoader();
			}
		});
	},
	
	render: function(responseData) {
		
		this.renderInfoBadge(responseData.infoBadgeHtml);
		this.renderListProducts(responseData.listProductsHtml);
		this.renderPagination(responseData.paginationHtml);
		
		if(!this.state.queryParams) {
			this.state.queryParams = AOW.parseUrlQuery(window.location.search) || {};
		}

		this.renderInputProductSearch(this.state.queryParams.search || "");
		this.renderSelectCount(this.state.queryParams.count);
		this.renderProductTypeDropdownItems(this.state.queryParams.productTypes || []);
		this.renderProductTypeTags(this.state.queryParams.productTypes || []);
		this.renderSelectSort(this.state.queryParams.sort || null);
		this.renderStateTag(this.state.queryParams.state || null);

		this.$el.find('.collapsible').collapsible({
			accordion: false
		});

		this.$el.find('.dropdown-button').dropdown();

		AOW.Dashboard.stopLoader();
	},
	
	renderInputProductSearch: function(search) {
		this.$inputProductSearch.val(search);
	},
	
	renderInfoBadge: function(infoBadgeHtml) {
		this.$infoBadgeContainer.html(infoBadgeHtml);
	},
	
	renderListProducts: function(listProductsHtml) {
		this.$productsContainer.html(listProductsHtml);
	},
	
	renderPagination: function(paginationHtml) {
		this.$paginationContainer.html(paginationHtml);
	},
	
	renderSelectCount: function(count) {
		this.$selectProductCount
			.val(count)
			.material_select();
	},
	
	renderSelectSort: function(sort) {
		this.$selectSort
			.val(sort)
			.material_select();
	},
	
	renderProductTypeDropdownItems: function(productTypes) {
		
		this.$productTypeItems.each(function(i, listItem) {
			$li = $(listItem);
			data = $li.data('product-type-id').toString();
			
			if (productTypes.indexOf(data) > -1)
				$li.addClass("hide");
			
			else
				$li.removeClass("hide");
		});
	},
	
	renderProductTypeTags: function(productTypes) {
		
		this.$productTypeTags.each(function(i, tag) {
			$tag = $(tag);
			tagData = $tag.data("product-type-id").toString();
			
			if (productTypes.indexOf(tagData) > -1)
				$tag.removeClass("hide");
			
			else
				$tag.addClass("hide");
		});
	},
	
	renderStateTag: function(state) {
		this.$stateTag.each(function(i, tag) {
			$tag = $(tag);
			tagData = $tag.data("state").toString();
			
			if (state === tagData)
				$tag.removeClass("hide");
			
			else
				$tag.addClass("hide");
		});
	},

	onSelectProduct: function(e) {
		this.selectedAllPages = false;
		var $product = $(e.currentTarget).closest('.product');

		//If shift key is on, find all products between this and the last one checked, and trigger the check on them
		if(e.shiftKey && this.$lastProductChecked !== null) {
			var currentIndex = $product.index('.product'),
				lastIndex = this.$lastProductChecked.index('.product'),
				i,
				toggleCheck = function(i, val) {
					return !val;
				};

			//ASC
			if(currentIndex > lastIndex) {
				for (i = lastIndex+1; i < currentIndex; i++) {
					$('.product').eq(i).find('input.filled-in').trigger('click');
				}
			} 
			//DESC
			else if(currentIndex < lastIndex) {
				for (i = lastIndex - 1; i > currentIndex; i--) {
					$('.product').eq(i).find('input.filled-in').trigger('click');
				}
			}
		}

		$product.toggleClass('checked');

		var productCount = $('.product.checked').length;

		$('.info-selected-products p.select').removeClass('hide');
		$('.info-selected-products p.all').addClass('hide');
		$('.info-selected-products p.select span#select-product-count').html(productCount);
		$('.info-selected-products-wrapper').addClass('active');

		if($('.product.checked').length > 0){
			$('.batch-actions').addClass('checked');

			if($('#product-count option:selected').val() == $('.product.checked').length){
				$('.select-all input#select-products').prop('checked', true);
			} else {
				$('.select-all label').addClass('checked');
			}
		} else {
			$('.batch-actions').removeClass('checked');
			$('.select-all input#select-products').prop('checked', false);
			$('.select-all label').removeClass('checked');
			$('.info-selected-products-wrapper').removeClass('active');
		}

		this.$lastProductChecked = $product;
	},

	onClickProduct: function(e) {
		//Stop here if we're clicking on buttons inside
		if($(e.target).closest('.btn-floating').length > 0 || $(e.target).closest('.btn-flat').length > 0 || $(e.target).closest('.dropdown-content').length > 0 || $(e.target).closest('.filled-in').length > 0 || $(e.target).closest('.filled-label').length > 0) {
			return true;
		}

		var $product = $(e.currentTarget);

		if($product.hasClass('checked')){
			$product.find('input.filled-in').prop('checked', false);
		} else {
			$product.find('input.filled-in').prop('checked', true);
		}

		this.onSelectProduct(e);
	},

	confirmUnsyncAll: function(e) {
		var me = this;
		var id = $(e.currentTarget).attr('data-id');
		
		AOW.UI.dialog({
			method: 'GET',
			url: 'd/'+me.slug+'/confirm-unsync-all',
			data:{
				id_product: id
			},
			success: function() {
				var $dialog = $(this);
				$dialog.on('submit', {$dialog: $dialog}, me.unsyncAll.bind(me));
			}
		});
	},

	unsyncAll: function(e) {
		e.preventDefault();

		var id_product = $(e.currentTarget).find('form').attr('data-id');
		var me = this,
			$dialog = e.data.$dialog,
			$submitBtn = $dialog.find('button[type="submit"]'),
			$deleteInStore = $dialog.find('#delete-in-store');
		
		AOW.UI.addLoader( $submitBtn.attr('disabled', true) );
		
		var postData = {
			id_integration_product: id_product,
			deleteInStore: ($deleteInStore.length > 0 && $deleteInStore.prop('checked')) ? 1 : 0
		};
		
		AOW.request({
			url: 'd/'+me.slug+'/unsync-all',
			method: 'POST',
			data: postData,
			success: function(response) {
				$dialog.closeDialog();

				AOW.UI.toast(__('account_ns_toast.unsyncedAll'));
				me.unSelectAll();
				me.pushStateToHistory(me.state);
			}
		});
	},

	confirmIgnoreAll: function(e) {
		var me = this;
		
		AOW.UI.dialog({
			method: 'GET',
			url: 'd/'+me.slug+'/confirm-ignore-all',
			data:{
				id_product: $(e.currentTarget).attr('data-id')
			},
			success: function() {
				var $dialog = $(this);
				$dialog.on('submit', {$dialog: $dialog}, me.ignoreAll.bind(me));
			}
		});
	},

	ignoreAll: function(e) {
		e.preventDefault();

		var me = this,
			$dialog = e.data.$dialog,
			$submitBtn = $dialog.find('button[type="submit"]');

		AOW.UI.addLoader( $submitBtn.attr('disabled', true) );
			
		AOW.request({
			method: 'POST',
			url: 'd/'+me.slug+'/ignore-all-variants',
			data: {
				id_integration_product: $(e.currentTarget).find('form').attr('data-id')
			},
			success: function(response) {
				$dialog.closeDialog();

				AOW.UI.toast(__('account_ns_toast.ignoredAll'));
				me.unSelectAll();
				me.pushStateToHistory(me.state);
			}
		});
	},

	confirmUnignoreAll: function(e) {
		var me = this;
		
		AOW.UI.dialog({
			method: 'GET',
			url: 'd/'+me.slug+'/confirm-unignore-all',
			data:{
				id_product: $(e.currentTarget).attr('data-id')
			},
			success: function() {
				var $dialog = $(this);
				$dialog.on('submit', {$dialog: $dialog}, me.unignoreAll.bind(me));
			}
		});
	},

	unignoreAll: function(e) {
		e.preventDefault();

		var me = this,
			$dialog = e.data.$dialog,
			$submitBtn = $dialog.find('button[type="submit"]');

		AOW.UI.addLoader( $submitBtn.attr('disabled', true) );
			
		AOW.request({
			method: 'POST',
			url: 'd/'+me.slug+'/unignore-all-variants',
			data: {
				id_integration_product: $(e.currentTarget).find('form').attr('data-id')
			},
			success: function(response) {
				$dialog.closeDialog();

				AOW.UI.toast(__('account_ns_toast.unignoredAll'));
				me.unSelectAll();
				me.pushStateToHistory(me.state);
			}
		});
	},

	confirmDeleteProduct: function(e) {
		var me = this;
		
		AOW.UI.dialog({
			method: 'GET',
			url: 'd/'+me.slug+'/confirm-delete-product',
			data:{
				id_product: $(e.currentTarget).attr('data-id'),
				product_name: $(e.currentTarget).attr('data-name')
			},
			success: function() {
				var $dialog = $(this);
				$dialog.on('submit', {$dialog: $dialog}, me.deleteProduct.bind(me));
			}
		});
	},

	deleteProduct: function(e) {
		e.preventDefault();

		var me = this,
			$dialog = e.data.$dialog,
			$submitBtn = $dialog.find('button[type="submit"]');

		AOW.UI.addLoader( $submitBtn.attr('disabled', true) );
			
		AOW.request({
			method: 'POST',
			url: 'd/'+me.slug+'/delete-product',
			data: {
				id_integration_product: $(e.currentTarget).find('form').attr('data-id')
			},
			success: function(response) {
				$dialog.closeDialog();

				AOW.UI.toast(__('account_ns_toast.deletedProduct'));
				me.unSelectAll();
				me.pushStateToHistory(me.state);
			}
		});
	},

	getBatchActionPostData: function() {
		var postData = {};

		if(this.selectedAllPages) {
			postData.selectedAllPages = 1;
			postData.url = this.getCurrentUrl(this.state); // This is used for filters 
		} else {
			postData.integration_products = [];
			$.each($('.product.checked'), function(){
				postData.integration_products.push($(this).attr('data-product'));
			});
		}

		return postData;
	},

	confirmBatchUnsync: function(e) {
		var me = this;
		
		AOW.UI.dialog({
			method: 'GET',
			url: 'd/'+me.slug+'/confirm-batch-unsync',
			data: {
				count: $('.product.checked').length
			},
			success: function() {
				var $dialog = $(this);
				$dialog.on('submit', {$dialog: $dialog}, me.batchUnsync.bind(me));
			}
		});
	},

	batchUnsync: function(e) {
		e.preventDefault();

		var postData = this.getBatchActionPostData();

		var me = this,
			$dialog = e.data.$dialog,
			$submitBtn = $dialog.find('button[type="submit"]'),
			$deleteInStore = $dialog.find('#delete-in-store');
		
		AOW.UI.addLoader($submitBtn.attr('disabled', true));

		postData.deleteInStore = ($deleteInStore.length > 0 && $deleteInStore.prop('checked')) ? 1 : 0;
		
		AOW.request({
			url: 'd/'+me.slug+'/batch-unsync'+AOW.buildUrlQuery(this.state.queryParams),
			method: 'POST',
			data: postData,
			success: function(response) {
				$dialog.closeDialog();

				AOW.UI.toast(__('account_ns_toast.batchUnsynced'));
				me.unSelectAll();
				me.pushStateToHistory(me.state);
			}
		});
	},

	confirmBatchIgnore: function(e) {
		var me = this;
		
		AOW.UI.dialog({
			method: 'GET',
			url: 'd/'+me.slug+'/confirm-batch-ignore',
			data: {
				count: $('.product.checked').length
			},
			success: function() {
				var $dialog = $(this);
				$dialog.on('submit', {$dialog: $dialog}, me.batchIgnore.bind(me));
			}
		});
	},

	batchIgnore: function(e) {
		e.preventDefault();

		var postData = this.getBatchActionPostData();

		var me = this,
			$dialog = e.data.$dialog,
			$submitBtn = $dialog.find('button[type="submit"]');

		AOW.UI.addLoader($submitBtn.attr('disabled', true));
			
		AOW.request({
			method: 'POST',
			url: 'd/'+me.slug+'/batch-ignore'+AOW.buildUrlQuery(this.state.queryParams),
			data: postData,
			success: function(response) {
				$dialog.closeDialog();

				AOW.UI.toast(__('account_ns_toast.batchIgnored'));
				me.unSelectAll();
				me.pushStateToHistory(me.state);
			}
		});
	},

	confirmBatchUnignore: function(e) {
		var me = this;
		
		AOW.UI.dialog({
			method: 'GET',
			url: 'd/'+me.slug+'/confirm-batch-unignore',
			data: {
				count: $('.product.checked').length
			},
			success: function() {
				var $dialog = $(this);
				$dialog.on('submit', {$dialog: $dialog}, me.batchUnignore.bind(me));
			}
		});
	},

	batchUnignore: function(e) {
		e.preventDefault();

		var postData = this.getBatchActionPostData();

		var me = this,
			$dialog = e.data.$dialog,
			$submitBtn = $dialog.find('button[type="submit"]');

		AOW.UI.addLoader($submitBtn.attr('disabled', true));
			
		AOW.request({
			method: 'POST',
			url: 'd/'+me.slug+'/batch-unignore'+AOW.buildUrlQuery(this.state.queryParams),
			data: postData,
			success: function(response) {
				$dialog.closeDialog();

				AOW.UI.toast(__('account_ns_toast.batchUnignored'));
				me.unSelectAll();
				me.pushStateToHistory(me.state);
			}
		});
	},

	confirmBatchDelete: function(e) {
		var me = this;
		
		AOW.UI.dialog({
			method: 'GET',
			url: 'd/'+me.slug+'/confirm-batch-delete',
			data: {
				count: $('.product.checked').length
			},
			success: function() {
				var $dialog = $(this);
				$dialog.on('submit', {$dialog: $dialog}, me.batchDelete.bind(me));
			}
		});
	},

	batchDelete: function(e) {
		e.preventDefault();

		var postData = this.getBatchActionPostData();

		var me = this,
			$dialog = e.data.$dialog,
			$submitBtn = $dialog.find('button[type="submit"]');

		AOW.UI.addLoader($submitBtn.attr('disabled', true));
			
		AOW.request({
			method: 'POST',
			url: 'd/'+me.slug+'/batch-delete'+AOW.buildUrlQuery(this.state.queryParams),
			data: postData,
			success: function(response) {
				$dialog.closeDialog();

				AOW.UI.toast(__('account_ns_toast.batchDeleted'));
				me.unSelectAll();
				me.pushStateToHistory(me.state);
			}
		});
	},

	clickSelectAll: function(e) {
		e.preventDefault();
		this.selectAll();
	},

	clickUnSelectAll: function(e) {
		e.preventDefault();
		this.unSelectAll();
	},

	selectAll: function() {
		var me = this;

		this.selectedAllPages = false;

		$('.select-all input#select-products').prop('checked', true);
		$('.batch-actions').addClass('checked');
		$('.product').addClass('checked');
		$('.product input.filled-in').prop('checked', true);

		var productCount = $('.product.checked').length;

		$('.info-selected-products p.all').removeClass('hide');
		$('.info-selected-products p.all span#select-product-count-page').html(productCount);
		$('.info-selected-products-wrapper').addClass('active');
		$('.info-selected-products p.select').addClass('hide');
		$('.select-all label').removeClass('checked');
	},

	unSelectAll: function() {
		var me = this;

		this.selectedAllPages = false;

		$('.select-all input#select-products').prop('checked', false);
		$('.select-all label').removeClass('checked');
		$('.batch-actions').removeClass('checked');
		$('.product').removeClass('checked');
		$('.product input.filled-in').prop('checked', false);
		$('.info-selected-products-wrapper').removeClass('active');
	},

	onClickSelectAllPages: function(e) {
		e.preventDefault();

		this.selectedAllPages = true;

		$('.select-all input#select-products').prop('checked', true);
		$('.batch-actions').addClass('checked');
		$('.product').addClass('checked');
		$('.product input.filled-in').prop('checked', true);

		var productCount = $('#select-product-total-count').text(); //TODO: Fix this count to the actual total count

		$('.info-selected-products p.all').addClass('hide');
		$('.info-selected-products-wrapper').addClass('active');
		$('.info-selected-products p.select').removeClass('hide');
		$('.info-selected-products p.select span#select-product-count').html(productCount);
		$('.select-all label').removeClass('checked');
	},

	refreshProductsAfterSynced: function(e) {
		var me = this;

		function checkIsSyncingProducts() {
			AOW.request({
				url: 'd/'+me.slug+'/is-syncing-products',
				method: 'GET',
				success: function(response) {
					if (response.data.isSyncingProducts === false) {
						me.requestProductsAjax(me.state);
						clearInterval(interval);
						
						// Remove info element informing user that products are still syncing
						me.$syncProductsAlert.remove();

						//Reenable button
						$('#refresh-products-btn').removeClass('disabled');

						// Display error messages if any
						if (typeof response.data.errorMsg !== 'undefined') {
							me.$errorAlert.removeClass('hide');
							me.$errorMessage.html(response.data.errorMsg);
						}
					}
				},
			}).fail(function () {
				clearInterval(interval);
				// Most likely the store has been flagged.
				window.location.reload(); // Redirect user to reconnect their store
			});
		}

		// For the first time, check if products are still syncing after 10 seconds.
		setTimeout(checkIsSyncingProducts, 10000);

		// After the first time, check every 30 seconds to see if app has finished syncing products and refresh product list on page if finished syncing.
		var interval = setInterval(checkIsSyncingProducts, 30000);
	},
	refreshProductQueue: function(e) {
		var me = this;

		let checkIsAddingProducts = () => {
			AOW.request({
				url: 'd/'+me.slug+'/product-queue',
				method: 'GET',
				success: function(response) {
					me.productsInQueue = response.data['products-in-queue'];

					if(response.data['products-in-queue'] <= 0) {
						me.requestProductsAjax(me.state);
						clearInterval(me.productQueueInterval);
						
						// Remove info element informing user that products are still syncing
						me.$addingProductsAlert.remove();
					}
				},
			}).fail(function () {
				clearInterval(me.productQueueInterval);
				// Most likely the store has been flagged.
				window.location.reload(); // Redirect user to reconnect their store
			});
		}

		// After the first time, check every 10 seconds to see if app has finished syncing products and refresh product list on page if finished syncing.
		this.productQueueInterval = setInterval(checkIsAddingProducts, 10000);
	}
});