require('../Dashboard');

AOW.Dashboard.Settings = function(slug) {
	AOW.Dashboard.call(this);
	
	this.slug = slug;
	
	this.$settingsView =		this.$el.find('#settings-view');
	this.$storeAutopayOn		= this.$settingsView.find('#store_autopay_on');
	this.$storeAutopayOff		= this.$settingsView.find('#store_autopay_off');
	this.$storePaymentMethod	= this.$settingsView.find('#store_paymentmethod');
	this.$storeBillingAddress	= this.$settingsView.find('#store_billing_address');
	this.$removeStoreBtn		= this.$settingsView.find('#removeStore-btn');

	this.init();
};

AOW.Dashboard.Settings.prototype = Object.assign({}, AOW.Dashboard.Settings.prototype, {
		
	init: function() {
		
		this.$storeAutopayOn.on('change', {action: "on"}, this.toggleDropdowns.bind(this));
		
		this.$storeAutopayOff.on('change', {action: "off"}, this.toggleDropdowns.bind(this));
		
		this.$removeStoreBtn.on('click', AOW.Dashboard.prototype.onRemoveStoreBtnClick.bind(this));
	},
	
	toggleDropdowns: function(e) {
		var isChecked = $(e.currentTarget).is(':checked'),
			isDisabled = isChecked && e.data.action === "off";
			
		this.$storePaymentMethod.prop('disabled', isDisabled).material_select();
		this.$storeBillingAddress.prop('disabled', isDisabled).material_select();
	}
});