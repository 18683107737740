AOW.ShippingTables = function(slug) {
	if(AOW.Dashboard){ AOW.Dashboard.call(this); }
	this.slug = slug;
	this.init();
};

AOW.ShippingTables.prototype = Object.assign({}, AOW.ShippingTables.prototype, {
	init: function() {
		this.initTabs();
		this.initFilters('weight');
		this.initFilters('product');

		$('#rates-by-weight-filter-country').trigger('change');
	},
	initTabs: function() {
		$('#shipping-view-tabs').find('label').each(function() {
			if($('#' + $(this).attr('for')).attr('checked')) $(this).addClass('active');
		});

		$('input[name="shipping-view-sections"]').on('change', function() {
			$('#shipping-view-tabs').find('label').removeClass('active');
			$('#shipping-view-tabs').find('label[for="'+$(this).attr('id')+'"]').toggleClass('active', $(this).attr('checked'));
		});
	},
	initFilters: function(category) {
		// ==== Countries
		$('#rates-by-'+category+'-filter-country').on('change', function(){
			$('#rates-by-'+category+'-filters-us, #rates-by-'+category+'-filters-ca, #rates-by-'+category+'-filters-in').hide();
			$('#rates-by-'+category+'-us-prices, #rates-by-'+category+'-ca-prices, #rates-by-'+category+'-in-prices').hide();
			var selected = $(this).find('option:selected').val();
			$('#rates-by-'+category+'-'+selected+"-prices").show();
			$('#rates-by-'+category+'-filters-'+selected).show();

			if(selected == "us" && $('#rates-by-'+category+'-filter-average-us').find('option:selected').val() == "average-zone") {
				$('.rates-by-'+category+'-states').show();
			} else {
				$('.rates-by-'+category+'-states').hide();
			}

			$('#rates-by-'+category+'-filter-type-'+selected).trigger('change');
		});
		// ====

		// ==== US

		$([
			'#rates-by-'+category+'-filter-type-us',
			'#rates-by-'+category+'-filter-average-us',
			'#rates-by-'+category+'-filter-zone',
		].join(',')).on('change', function(){

			var avg = $('#rates-by-'+category+'-filter-average-us').find('option:selected').val();
			var type = $('#rates-by-'+category+'-filter-type-us').find('option:selected').val();
			var zone = "";
			$('#rates-by-'+category+'-us-prices').find('table').hide();
			$('#rates-by-'+category+'-filter-zone').closest('.input-field').toggle(avg == 'average-zone');

			var ratesByFilterZoneSelectElm = $('#rates-by-'+category+'-filter-zone');

			// grab list of zones 
			var zoneList = $('#rates-by-weight-filter-zone-values option[data-id-shipping-slug="'+type+'"]').clone();

			// copy the list of zones from hidden select into the active select menu
			ratesByFilterZoneSelectElm.html(zoneList);
	
			// re-init material select menu
			if ($(this).attr('id') !== 'rates-by-'+category+'-filter-zone') {
				ratesByFilterZoneSelectElm.material_select();
			}

			// get value of selected zone 
			var z = ratesByFilterZoneSelectElm.prevAll("input").val();
			zone = ratesByFilterZoneSelectElm.find("option[data-name='"+z+"']").val();
	
			var data = {
				category: category,
				country: 'us',
				type: type,
				average: avg,
				zone: zone
			};

			$elem = $('#rates-by-'+category).find('.shipping-view-table').find('.card-content');

			$elem.html('');
			AOW.UI.addLoader($elem);
			AOW.request({
				url: 'get-shipping-table',
				data: data,
				method: 'GET',
				success: function(response) {
					AOW.UI.removeLoader($elem);
					$elem.html($(response.data.data));
				}
			});

			// States list
			if(avg == 'average-zone'){
				$('.rates-by-'+category+'-states').show();
				$('.rates-by-'+category+'-states').find('ul').hide();
				$('.rates-by-'+category+'-states').find('#rates-by-'+category+'-states-'+zone).show();
			} else {
				$('.rates-by-'+category+'-states').hide();
			}
		});
		// ====

		// ==== CA
		$([
			'#rates-by-'+category+'-filter-type-ca',
			'#rates-by-'+category+'-filter-average-ca',
			'#rates-by-'+category+'-filter-province',
		].join(',')).on('change', function(){
			var avg = $('#rates-by-'+category+'-filter-average-ca').find('option:selected').val();
			var type = $('#rates-by-'+category+'-filter-type-ca').find('option:selected').val();
			$('#rates-by-'+category+'-ca-prices').find('table').hide();
			$('#rates-by-'+category+'-filter-province').closest('.input-field').toggle(avg == 'average-province' && type !== 'canada-mail');
			$('#rates-by-'+category+'-filter-average-ca').closest('.input-field').toggle(type !== 'canada-mail');

			var data = {
				category: category,
				country: 'ca',
				type: type,
				average: avg,
				province: $('#rates-by-'+category+'-filter-province').find('option:selected').val()
			};

			$elem = $('#rates-by-'+category).find('.shipping-view-table').find('.card-content');

			$elem.html('');
			AOW.UI.addLoader($elem);
			AOW.request({
				url: 'get-shipping-table',
				data: data,
				method: 'GET',
				success: function(response) {
					AOW.UI.removeLoader($elem);
					$elem.html($(response.data.data));
				}
			});
		});
		// ====
		
		// ==== IN
		$([
			'#rates-by-'+category+'-filter-type-in',
			'#rates-by-'+category+'-filter-in-country',
		].join(',')).on('change', function(){
			var type = $('#rates-by-'+category+'-filter-type-in').find('option:selected').val();
			$('#rates-by-'+category+'-in-prices').find('table').hide();
			$('#rates-by-'+category+'-filter-in-country').closest('.input-field').toggle(type == 'dhl-express-worldwide');

			var data = {
				category: category,
				type: type,
				country: type == 'dhl-express-worldwide' ? $('#rates-by-'+category+'-filter-in-country').find('option:selected').val() : null
			};

			$elem = $('#rates-by-'+category).find('.shipping-view-table').find('.card-content');

			$elem.html('');
			AOW.UI.addLoader($elem);
			AOW.request({
				url: 'get-shipping-table',
				data: data,
				method: 'GET',
				success: function(response) {
					AOW.UI.removeLoader($elem);
					$elem.html($(response.data.data));
				}
			});
		});
		// ====
	}
});