import Model3DViewer from './Model3DViewer';

/**
 * Saves the params and then calls init to start the container's creation
 * @param {jQuery} 	_container	jQuery element of the target div
 * @param {int}		_width 		The width of the viewer(s)
 * @param {int} 	_height		The height of the viewer(s)
 * @param {array}	_args		An array of objects, each with the following properties
 *                       		'product'		: The product to display
 *                       		'model_name'	: The name of the model to load
 *                       		'variant'		: The variant of the product
 */
function Model3DViewerContainer(_container, _width, _height, _args, _onLoadDone) {
	// Make sure the params aren't undefined
	if (typeof _container == 'undefined') 	M3DVCE(0);
	if (typeof _width == 'undefined') 		M3DVCE(1);
	if (typeof _height == 'undefined') 		M3DVCE(2);
	if (typeof _args == 'undefined') 		M3DVCE(3);

	// Save the params
	this.container	= _container;
	this.width		= _width;
	this.height		= _height;
	this.args		= _args;

	// Create additional params
	this.modelViewers		= [];
	this.slugs				= [];
	this.finishedLoading	= [];
	this.currentViewer		= null;
	this.camera				= null;
	this.controls 			= null;

	// Make sure aow registered that
	window.aow.modelViewerContainer = this;

	// Keep _onLoadDone, no check necessary, possible errors will be explicit enough
	this.onLoadDone = _onLoadDone;

	// Initilize everything
	this.init();
}

/**
 * Flushes most of the objects and initialises the container according to it's params
 * Also initializes each of the container's viewers, can technically be used as a reset
 */
Model3DViewerContainer.prototype.init = function(){
	var me = this;

	// Flush the viewers 
	this.modelViewers = [];
	this.slugs = [];

	var firstViewer = null;

	// Keep track of all the viewers load status
	for (var obj in this.args){
		if(!this.args.hasOwnProperty(obj)) continue;
		this.finishedLoading.push(false);
	}

	// Function called by each viewer when they're done loading the object
	var onObjectLoad = function(){
		var index = this.containerIndex;
		me.finishedLoading[index] = true;
		if(me.finishedLoading.every(function(e){return e;})){
			// We're done loading the objects so we can safely display the first viewer
			me.display(firstViewer.model_name);
			// Call whatever's in onLoadDone if there's anything
			if(me.onLoadDone){
				me.onLoadDone();
			}
			return;
		}
	};

	// Create a viewer for each object in args and initialize them
	for (obj in this.args){
		if(!this.args.hasOwnProperty(obj)) continue;

		this.slugs.push(this.args[obj].model_name);

		var index = this.slugs.indexOf(this.args[obj].model_name);
		var modelViewer = new Model3DViewer(this.container, this.width, this.height);

		//Just quit if there's no support of WebGL
		if(modelViewer.nosupport)
			break;

		modelViewer.isContained = true;
		modelViewer.containerIndex = index;
		modelViewer.onObjectLoad = onObjectLoad;

		modelViewer.init(this.args[obj].child_product, this.args[obj].model_name, this.args[obj].variant);

		// Hide all the canvases except one (We need one for html flow --")
		if(firstViewer) modelViewer.renderer.domElement.style.display = 'none';

		firstViewer = firstViewer || modelViewer;

		this.modelViewers.push(modelViewer);
	}
};

/**
 * Checks the list of slugs and displays the corresponding viewer if one is found
 * @param 	{string} 	slug 	The slug of the viewer to display
 */
Model3DViewerContainer.prototype.display = function(slug){
	// Check if the slug actually exists and is a viewer
	var index = this.slugs.indexOf(slug);
	if (index == -1) {
		M3DVCE(4, slug);
		return;
	}
	if (!this.modelViewers[index]) {
		M3DVCE(5);
		return;
	}

	// Stop all of them before activating another
	this.stopAllViewers();

	// Activates this viewer
	var modelViewer = this.modelViewers[index];
	modelViewer.setContainer(this.container);
	modelViewer.paused = false;
	modelViewer.renderer.domElement.style.display = 'block';

	// Data management
	this.saveCameraFromViewer(this.currentViewer);
	this.currentViewer = modelViewer;
	this.loadCameraToViewer(modelViewer);
};

/**
 * Pauses all the viewers so they stop rendering
 */
Model3DViewerContainer.prototype.stopAllViewers = function(){
	for(var i = 0; i < this.modelViewers.length; i++){
		this.modelViewers[i].paused = true;
		this.modelViewers[i].renderer.domElement.style.display = 'none';
	}
};

/**
 * Keeps a specific viewer's camera in memory so we can apply it to another viewer later
 * Doesn't do anything if the viewer is null (Useful for first-time run)
 * @param 	{Model3DViewer} 	modelViewer 	The viewer with the camera we want to save
 */
Model3DViewerContainer.prototype.saveCameraFromViewer = function(modelViewer){
	this.camera = modelViewer ? modelViewer.camera : this.camera;
	this.controls = modelViewer ? modelViewer.controls : this.controls;
};

/**
 * Loads the saved camera and applies it to the specified viewer
 * Doesn't do anything if we don't have a camera saved
 * @param 	{Model3DViewer} 	modelViewer 	The viewer on which to apply the camera
 */
Model3DViewerContainer.prototype.loadCameraToViewer = function(modelViewer){
	modelViewer.camera = this.camera || modelViewer.camera;
	modelViewer.controls = this.controls || modelViewer.controls;
};

/**
 * Here we want to remove as many references as possible
 * so that the GC can do a better (And hopefully faster) job
 */
Model3DViewerContainer.prototype.deleteSelf = function(){
	for(var i = 0; i < this.modelViewers.length; i++){
		var modelViewer = this.modelViewers[i];
		if(modelViewer !== null) {
			Model3DViewer.destroy(modelViewer);
		}
	}
	this.modelViewers = null;
	this.camera = null;
	this.controls = null;
	this.currentViewer = null;
	window.aow.modelViewerContainer = null;
	$(window).off('resize.modelViewer');
};

export default Model3DViewerContainer;

/**
 * Function used for errors in Model3DViewerContainer
 * @param 	{int} 	n The number of the error to throw
 */
function M3DVCE(n, a){
	a = a || '';
	console.log([
		'Parameter container undefined ' + a, 	// 0
		'Parameter width undefined ' + a,		// 1
		'Parameter height undefined ' + a,		// 2
		'Parameter args undefined ' + a,		// 3
		'Required slug invalid ' + a,			// 4
		'Retrieved modelViewer broken ' + a		// 5
	][n]);
}