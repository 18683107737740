require('../../Dashboard');

AOW.Dashboard.SyncAOWCollection = function($el, slug) {
	
	this.$el = $el;
	this.slug = slug;
	this.itemType = "collection";
	
	AOW.Dashboard.SyncAOWItem.call(this);
	
	this.init();
};

// SyncAOWCollection extends SyncAOWItem
AOW.Dashboard.SyncAOWCollection.prototype = AOW.Dashboard.SyncAOWItem.prototype;
AOW.Dashboard.SyncAOWCollection.prototype.constructor = AOW.Dashboard.SyncAOWCollection;

AOW.Dashboard.SyncAOWCollection.prototype = Object.assign({}, AOW.Dashboard.SyncAOWCollection.prototype, {
	
	select2Data: function(params) {
		return params;
	},
	
	select2ProcessResult: function(response, params) {
		// parse the results into the format expected by Select2
		// since we are using custom formatting functions we do not need to
		// alter the remote JSON data, except to indicate that infinite
		// scrolling can be used
		params.page = params.page || 1;

		var items = response.data.items;

		return {
			results: items,
			pagination: {
				more: (params.page * 25) < parseInt(response.data.count_items,10)
			}
		};
	},
	
	templateResult: function(item) {
		if (item.loading) return item.name;

		var markup = '';
		markup += '<div class="result-item result-productDesign">'+
					'<img src="'+item.preview+'" height="48"/>'+
					'<div class="result-text"><strong>' +item.name+ '</strong></div>'+
				'</div>';

		markup += '';

		return markup;
	},
	
	templateSelection: function(item) {
		return item.name;
	},
	
});