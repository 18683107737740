require('../Dashboard');

AOW.Dashboard.Home = function(slug) {
	AOW.Dashboard.call(this);

	this.slug				= slug;
	this.chartsData			= null;
	this.ordersOverview		= null;
	this.lastProductSales	= null;
	
	this.$el				= $('#dashboard-stats');
	this.$upperStats		= this.$el.find('.upper-stats');
	this.$midStats			= this.$el.find('.mid-stats');
	this.$bottomStats		= this.$el.find('.bottom-stats');
	this.$lastProductSales	= this.$el.find('.last-product-sales');
	
	this.init();
};

AOW.Dashboard.Home.prototype = Object.assign({}, AOW.Dashboard.Home.prototype, {
	init: function() {
		
		var me = this;
		
		AOW.request({
			method: 'GET',
			url: 'd/'+this.slug+'/sales',
			success: this.onSalesResponse.bind(me),
			error: function() {
			}
		});
	},
	
	onSalesResponse: function(response) {
		
		this.chartsData = response.data.chartsData;
		this.ordersOverview = response.data.ordersOverview;
		this.lastProductSales = response.data.lastProductSales;
		
		this.renderSalesStats( this.$upperStats);
		this.renderSalesStats( this.$midStats);
		this.renderSalesStats( this.$bottomStats);
		
		this.renderLastSales();
	},
	
	renderSalesStats: function($statsEl) {
		var me = this;
		
		var lastDays = parseInt($statsEl.data('lastdays'));
		
		var	$statsTime = $statsEl.find('.stats-time'),
			$title = $statsTime.find('.title'),
			$date = $statsTime.find('.date'),
			$statsCount = $statsEl.find('.stats-count'),
			$orders = $statsCount.find('.orders'),
			$profit = $statsCount.find('.profit');
		
		
		var data = this.chartsData.slice(-lastDays).reduce(function(a, b) {
			return {
				sales: a.sales + b.sales,
				profit: a.profit + b.profit
			};
		}, {
			sales: 0,
			profit: 0
		});
		
		$orders.text(data.sales);
		$profit.text("$"+data.profit.toFixed(2));
		
	},
	
	initOverviewGraph: function() {
		
		var graphCount = 0,
			shippedCount = 0,
			totalCount = 0;
		for(var status in this.ordersOverview ) {
			
			totalCount += parseInt(this.ordersOverview[status]);
			
			if( status === "shipped" ) shippedCount += this.ordersOverview[status];
		
			if( ["awaitingPayment","paymentProcessed","inProduction"].indexOf(status) > -1 ) graphCount += this.ordersOverview[status];
		}
		
		$ordersOverview = this.$el.find(".orders-overview");
		$statsCount = $ordersOverview.find('.stats-count');
		
		$statsCount.find('.orders').text(totalCount);
		$statsCount.find('.shipped').text(shippedCount);
	},
	
	renderLastSales: function() {
		
		var $collection = this.$lastProductSales.find('ul.collection'),
			productsHtml = '';
			
		if(this.lastProductSales.length === 0) {
			productsHtml = '<li class="collection-item" style="margin-left:-57px;">It looks like you haven\'t made a sale yet!</li>';
		} else {
			productsHtml = this.lastProductSales.reduce(function(currentHtml, product) {
				
				return currentHtml +
				'<li class="collection-item avatar">' +
					'<img class="circle" src="'+product.preview+'" />'+
					'<div class="content">'+
						'<span class="title">'+product.title+'</span>'+
						'<p class="location"><i class="material-icons">place</i><span>'+product.location+'</span></p>'+
					'</div>'+
				'</li>';
				
			}, "");
		}
		
		$collection.html(productsHtml);
	}
	
});