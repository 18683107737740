AOW.Dropship = function() {};
AOW.Dropship.init = function() {
	if($('#etsy-page').length > 0 || 
		$('#shopify-page').length > 0 || 
		$('#bigcartel-page').length > 0 || 
		$('#woocommerce-page').length > 0 || 
		$('#bigcommerce-page').length > 0 ||
		$('#squarespace-page').length > 0) {
		$.each($('.packaging-video'), function(i){
			var video = $(this).find('video');

			video.on('pause', function(){
				var vidParent = video.parent();
				var iconReplay = vidParent.find('.material-icons');
				
				iconReplay.removeClass('hide');

				iconReplay.on('click', function(){
					iconReplay.addClass('hide');
					video[0].play();
				});
			});
		});

		$('.packaging-tab').on('click', function(){
			var vidContainer = $(this).attr('data-id');
			$('#'+vidContainer).find('.material-icons').addClass('hide');

			var vidToPlay = $('#'+vidContainer).find('video');
			vidToPlay[0].play();
		});
	}

	if($('.drop-ship-page').length > 0){
		//Init tooltips
		$('.integration-store').first().addClass('active');

		$('.arrow').on('click', function(){
			var arrow = $(this);
			var activeStore = $('.integration-store.active');
			var step = activeStore.attr('data-step');

			if(arrow.hasClass('prev')) {
				if(step == 1){
					step = 6;
				} else {
					step--;
				}
				activeStore.removeClass('active');
				$('.integration-store[data-step="'+step+'"]').addClass('active');
			} else {
				if(step == 6){
					step = 1;
				} else {
					step++;
				}
				activeStore.removeClass('active');
				$('.integration-store[data-step="'+step+'"]').addClass('active');
			}
			var storeHeight = $('.integration-store.active').outerHeight();
			$('#comparison-list').css('height',storeHeight + 10 + 'px');
		});

		$(window).on('resize.dropship', function() {
			var storeHeight = $('.integration-store.active').outerHeight();
			$('#comparison-list').css('height',storeHeight + 10 + 'px');
		});
		$(window).trigger('resize.dropship');
	}
};